import {
  HHAlert,
  HHBreadcrumbs,
  HHLink,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BillingLeftPanel from '../components/billing-tool-left-panel';
import { ContractPackageFormValues } from '../components/contract/package/custom-package-types';
import { ContractPackageForm } from '../components/contract/package/form';
import LoadingComponent from '../components/utils/loading';
import { routes } from '../constants/strings/routes';
import { useSnackbarContext } from '../context/snackbar.context';
import { useClientInsurersById } from '../hooks/client-insurer-hook';
import {
  GetContractsByClientInsurerDocument,
  UpdateContractInput,
  useGetContractsByClientInsurerQuery,
  useUpdateContractMutation,
} from '../types';
import {
  ActiveStatus,
  computeActiveStatus,
  getClientsInsurerPartnership,
} from '../utils/contract-utils';

export const CLIENT_INSURER_EDIT_CONTRACT_PACKAGE_CONTAINER_TEST_ID =
  'client-insurer-edit-contract-package-container';

const ClientsInsurerIdEditContractPackage = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams<{ id: string; contractId: string }>();
  const clientInsurerId = parseInt(params.id ?? '0', 10);
  const contractId = parseInt(params.contractId ?? '0', 10);
  const { setSnackbarState } = useSnackbarContext();

  const {
    data: contractsData,
    error: contractsError,
    loading: contractsLoading,
  } = useGetContractsByClientInsurerQuery({
    variables: {
      id: clientInsurerId,
      includeVoided: true,
    },
  });

  const {
    data: ciData,
    loading: ciLoading,
    error: ciError,
  } = useClientInsurersById(clientInsurerId);

  const [
    updateContractMutation,
    {
      data: updateContractData,
      loading: updateContractLoading,
      error: updateContractError,
    },
  ] = useUpdateContractMutation();

  const updateContract = async (
    contractId: number,
    updateInput: UpdateContractInput,
  ): Promise<void> => {
    try {
      await updateContractMutation({
        variables: {
          contractId,
          contractInput: updateInput,
        },
        refetchQueries: [
          {
            query: GetContractsByClientInsurerDocument,
            variables: { id: clientInsurerId, includeVoided: true },
          },
        ],
      });
    } catch (error) {
      throw new Error(`Failed to update contract: ${error}`);
    }
  };

  const handleCancel = (): void => {
    navigate(`${routes.billing.main}/${clientInsurerId}`);
  };

  const currentContracts = contractsData?.getContracts ?? [];
  const currentContract = contractsData?.getContracts?.find(
    ({ id }) => id === contractId,
  );

  useEffect(() => {
    if (updateContractData) {
      navigate(`${routes.billing.main}/${clientInsurerId}`);
      setSnackbarState('Contract updated successfully', 'success', 4000);
    }
  }, [updateContractData, clientInsurerId, navigate, setSnackbarState]);

  useEffect(() => {
    if (updateContractError) {
      setSnackbarState('Failed to update contract', 'error', 4000);
    }
  }, [setSnackbarState, updateContractError]);

  if (!currentContract) {
    return (
      <HHAlert hhVariant="outlined" severity="error">
        <HHTypography hhVariant="body">
          There was an error loading current contracts, contract create
          unavailable
        </HHTypography>
      </HHAlert>
    );
  }

  let currentActiveStatus = ActiveStatus.Unknown;
  if (currentContract) {
    currentActiveStatus = computeActiveStatus(
      currentContract.void,
      currentContract.startDate,
      currentContract.endDate,
    );
  }

  const formSubmit = async (
    values: ContractPackageFormValues,
  ): Promise<void> => {
    const additional = {
      clientsInsurerId: clientInsurerId,
      clientId: ciData?.client.id,
      insurerId: ciData?.insurer.id,
      procedureCodes: [],
    };

    /**Inputs */
    const contractInput = {
      ...additional,
      startDate: values.startDate,
      endDate: values.endDate,
      userAnnualCap: values.userAnnualCap,
      currency: values.currency,
      void: values.void,
      packageEnabled: true,
      partnershipId: values.partnershipId,
      contractPackages: values.contractPackages.map(cp => ({
        ...cp,
        startDate: values.startDate,
        void: values.void,
      })),
    };

    try {
      await updateContract(contractId, contractInput);
    } catch (error) {
      throw new Error(`Failed to update contract: ${error}`);
    }
  };

  /**early returns for error states */
  if (!ciLoading && (ciError || !ciData)) {
    return (
      <HHAlert hhVariant="outlined" severity="error">
        <HHTypography hhVariant="body">
          There was an error loading the client insurer.
        </HHTypography>
      </HHAlert>
    );
  }

  return (
    <HHStack
      data-testid={CLIENT_INSURER_EDIT_CONTRACT_PACKAGE_CONTAINER_TEST_ID}
      direction="row"
      height="100%"
    >
      {ciData && <BillingLeftPanel data={ciData} />}
      <HHStack direction="column" spacing={4} paddingLeft={6} flex={2}>
        <HHBreadcrumbs sx={{ paddingTop: 6 }} aria-label="breadcrumb">
          <HHLink color="inherit" href={routes.billing.home} underline="hover">
            Home
          </HHLink>
          <HHLink color="inherit" href={routes.billing.home} underline="hover">
            Billing
          </HHLink>
          <HHLink
            color="inherit"
            underline="hover"
            href={`${routes.billing.main}/${clientInsurerId}`}
          >
            {`Client Insurer ${clientInsurerId}`}
          </HHLink>
          <HHTypography color="text.primary" hhVariant="variant-bypass">
            Edit contract
          </HHTypography>
        </HHBreadcrumbs>
        <HHTypography hhVariant="h2">Edit Contract</HHTypography>
        {updateContractLoading || ciLoading || contractsLoading ? (
          <LoadingComponent center />
        ) : (
          <ContractPackageForm
            currentContracts={currentContracts}
            onSubmitCallback={formSubmit}
            onCancel={handleCancel}
            activeStatus={currentActiveStatus}
            wholeFormDisabled={currentActiveStatus !== ActiveStatus.Future}
            disableSubmit={!!contractsError}
            editId={contractId}
            hideVoid={false}
            submitTitle="Save Contract"
            startDateDisablePast={false}
            partnershipId={getClientsInsurerPartnership(ciData)?.id}
          />
        )}
      </HHStack>
    </HHStack>
  );
};

export default ClientsInsurerIdEditContractPackage;
