import {
  HHButton,
  HHChip,
  HHDivider,
  HHGrid,
  HHLoadingButton,
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import {
  Add,
  DataSaverOnRounded,
  Delete,
  Edit,
  RuleRounded,
} from '@mui/icons-material';
import { IconButton, LinearProgress, Menu, MenuItem } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RuleDefinition } from '../../../../types';
import { HingeConnectAutocomplete } from '../../../hinge-connect/components/hinge-connect-autocomplete';
import { HCModal } from '../../../hinge-connect/components/hinge-connect-modal';
import { NoRowsMessage } from '../../../hinge-connect/components/no-rows';
import {
  buttonLabels,
  sourceAutoCompleteOptions,
} from '../../../hinge-connect/constants/hinge-connect-constants.constant';
import { CreateCriterionForm } from '../../../hinge-connect/rules/components/create-criterion-form';
import {
  newRuleFormContent,
  ruleTextFields,
} from '../../../hinge-connect/rules/constants/rules.constant';
import {
  RuleStatus,
  useGetRuleQuery,
  useUpdateRuleMutation,
} from '../../../hinge-connect/rules/types';
import { useListTagsQuery } from '../../../hinge-connect/tags/types';
import { csvToArray } from '../../../hinge-connect/utils/csv-to-array';
import { handleInputChange } from '../../../hinge-connect/utils/input-change-handler';
import { tabRoutes } from '../../constants/member-data-services-constants.constant';
import { targetedEnrollmentRoutes } from '../constants/targeted-enrollment.constants';
import { EditCriterionForm } from './edit-criterion-form';

export const EditTaggingRuleForm = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const {
    data: ruleData,
    refetch,
    loading,
  } = useGetRuleQuery({
    variables: { id: id ?? '' },
  });
  const [source, setSource] = useState('');
  const [, setStatus] = useState<RuleStatus>(RuleStatus.Draft);
  const [name, setName] = useState('');
  const [resourceTypes, setResourceTypes] = useState('');
  const [operator, setOperator] = useState('all');
  const [criteria, setCriteria] = useState<
    { id: string; name: string; operator: string; key: string; value: string }[]
  >([]);
  const [tagNames, setTagNames] = useState<string[]>([]);
  const [selectedTagName, setSelectedTagName] = useState<string>('');
  const [nextCriterionId, setNextCriterionId] = useState<number>(1);

  const { data } = useListTagsQuery();
  const [updateRule, { loading: updateRuleLoading }] = useUpdateRuleMutation();

  useEffect(() => {
    if (data) {
      setTagNames(data.listTags.tags.map(tag => tag.name));
    }
  }, [data]);

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [criteriaMenuOpen, setCriteriaMenuOpen] = useState(false);
  const [criterionFormOpen, setCriterionFormOpen] = useState(false);
  const [editCriterion, setEditCriterion] = useState<null | {
    id: string;
    name: string;
    operator: string;
    key: string;
    value: string;
  }>(null);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (ruleData) {
      setSource(ruleData.getRule.source);
      setStatus(ruleData.getRule.status);
      setName(ruleData.getRule.definition.name);
      setResourceTypes(ruleData.getRule.resourceTypes?.join('') ?? '');
      setSelectedTagName(ruleData.getRule.actions?.[0]?.metadata.tagName ?? '');
      setOperator(ruleData.getRule.definition.operator);
      setCriteria(
        ruleData.getRule.definition.criteria.map((criterion, index) => ({
          ...criterion,
          id: criterion.id || index.toString(),
        })),
      );
      setNextCriterionId(ruleData.getRule.definition.criteria.length + 1);
    }
  }, [ruleData]);

  useEffect(() => {
    setIsFormValid(
      !!source && !!name && !!selectedTagName && (criteria?.length ?? 0) > 0,
    );
  }, [source, name, selectedTagName, criteria]);

  useEffect(() => {
    refetch();
  }, [id, refetch]);

  const handleOnSubmit = async (
    e: FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();
    const ruleDto = {
      source,
      resourceTypes:
        !resourceTypes || resourceTypes.trim() === ''
          ? []
          : csvToArray(resourceTypes),
      definition: { name, operator, criteria } as RuleDefinition,
      actions: [
        {
          type: 'CREATE_USER_TAG',
          metadata: { tagName: selectedTagName },
        },
      ],
    };
    try {
      await updateRule({
        variables: {
          id: id ?? '',
          updateRuleInput: ruleDto,
        },
      });
      navigate(
        `/${tabRoutes.baseRoute}/${tabRoutes.targetedEnrollment}/${targetedEnrollmentRoutes.list}`,
        { state: { successMessage: 'Rule updated successfully' } },
      );
    } catch (error) {
      console.error('Error updating rule:', error);
    }
  };

  const handleCancelClick = (): void => {
    navigate(
      `/${tabRoutes.baseRoute}/${tabRoutes.targetedEnrollment}/${targetedEnrollmentRoutes.list}`,
    );
  };

  const handleAddCriteria = (e: React.MouseEvent<HTMLElement>): void => {
    setCriteriaMenuOpen(true);
    setAnchorElement(e.currentTarget);
  };

  const handleSelectCriterion = (_: React.MouseEvent<HTMLElement>): void => {
    setCriteriaMenuOpen(false);
    setCriterionFormOpen(true);
  };

  const handleAddCriteriaSubmit = (criterion: {
    name: string;
    operator: string;
    key: string;
    value: string;
  }): void => {
    setCriterionFormOpen(false);
    const newCriterion = { ...criterion, id: nextCriterionId.toString() };
    setCriteria(criteria?.concat(newCriterion) ?? [newCriterion]);
    setNextCriterionId(nextCriterionId + 1);
  };

  const handleEditCriterion = (criterion: {
    id: string;
    name: string;
    operator: string;
    key: string;
    value: string;
  }): void => {
    setEditCriterion(criterion);
  };

  const handleEditCriterionSubmit = (updatedCriterion: {
    id: string;
    name: string;
    operator: string;
    key: string;
    value: string;
  }): void => {
    setCriteria(
      (criteria ?? []).map(criterion =>
        criterion.id === updatedCriterion.id ? updatedCriterion : criterion,
      ),
    );
    setEditCriterion(null);
  };

  const handleDeleteCriterion = (criterion: {
    id: string;
    name: string;
    operator: string;
    key: string;
    value: string;
  }): void => {
    setCriteria((criteria ?? []).filter(c => c.id !== criterion.id));
  };

  const getCriterionType = (operator: string): string => {
    if (operator === 'all' || operator === 'any') {
      return 'Rule';
    }
    return 'Criterion';
  };

  // Ensure each criterion has a unique id
  const criteriaWithId = criteria?.map((criterion, index) => ({
    ...criterion,
    id: criterion.id || index.toString(),
  }));

  const criteriaColumns: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      renderCell: (params): JSX.Element => (
        <HHTypography hhVariant="input-label">{params.value}</HHTypography>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      valueGetter: (params): string => getCriterionType(params.row.operator),
      renderCell: (params): JSX.Element => (
        <HHChip
          hhVariant="filled"
          color="primary"
          label={params.value}
          size="small"
        />
      ),
    },
    {
      field: 'operator',
      headerName: 'Operator',
      width: 200,
      renderCell: (params): JSX.Element => (
        <HHTypography hhVariant="input-label">{params.value}</HHTypography>
      ),
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 200,
      renderCell: (params): JSX.Element => (
        <HHTypography hhVariant="input-label">
          {typeof params.value === 'object'
            ? JSON.stringify(params.value)
            : (params.value ?? '')}
        </HHTypography>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params): JSX.Element => (
        <div data-testid={`criterion-actions-${params.row.id}`}>
          <IconButton
            color="primary"
            data-testid={`edit-criterion-button-${params.row.id}`}
            onClick={(): void => handleEditCriterion(params.row)}
          >
            <Edit />
          </IconButton>
          <IconButton
            color="error"
            data-testid={`delete-criterion-button-${params.row.id}`}
            onClick={(): void => handleDeleteCriterion(params.row)}
          >
            <Delete />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    (loading && <LinearProgress sx={{ marginTop: '2px' }} />) || (
      <HHGrid container>
        <HCModal
          isOpen={criterionFormOpen}
          handleClose={(): void => setCriterionFormOpen(false)}
          title={'Add Criterion'}
          maxWidth="lg"
          fullWidth
          component={
            <CreateCriterionForm
              onCancel={(): void => setCriterionFormOpen(false)}
              onSubmit={handleAddCriteriaSubmit}
            />
          }
        />

        {editCriterion && (
          <HCModal
            isOpen={!!editCriterion}
            handleClose={(): void => setEditCriterion(null)}
            title={'Edit Criterion'}
            maxWidth="lg"
            fullWidth
            component={
              <EditCriterionForm
                criterion={editCriterion}
                onCancel={(): void => setEditCriterion(null)}
                onSubmit={handleEditCriterionSubmit}
              />
            }
          />
        )}

        <HHGrid item xs={12}>
          <br />
          <HHTypography hhVariant="h2">Edit Tagging Rule</HHTypography>
          <br />
        </HHGrid>

        <HHGrid item xs={12}>
          <form data-testid="edit-tagging-rule-form" onSubmit={handleOnSubmit}>
            <HHGrid container spacing={2}>
              <HHGrid item xs={2}>
                <HHTextField
                  fullWidth
                  hhVariant="variant-bypass"
                  label="Name"
                  aria-label="Name"
                  data-testid="rule-name"
                  value={name}
                  size="medium"
                  onChange={(e): void => handleInputChange(e, setName)}
                />
              </HHGrid>
              <HHGrid item xs={2}>
                <HingeConnectAutocomplete
                  label={ruleTextFields.source.label}
                  value={source}
                  aria-label="Source"
                  data-testid="source-select"
                  dropdownOptions={sourceAutoCompleteOptions}
                  size="medium"
                  onChange={(value): void => setSource(value ?? '')}
                />
              </HHGrid>
              <HHGrid item xs={2}>
                <HingeConnectAutocomplete
                  label="Tag Name"
                  value={selectedTagName}
                  dataTestId="tag-name-select"
                  dropdownOptions={tagNames.map(tagName => ({
                    label: tagName,
                    value: tagName,
                  }))}
                  size="medium"
                  onChange={(value): void => setSelectedTagName(value ?? '')}
                />
              </HHGrid>
              <HHGrid item xs={6} />
              <HHGrid item xs={6} />
            </HHGrid>
            <HHGrid item xs={12}>
              <HHDivider sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
            </HHGrid>
            <HHGrid container>
              <HHGrid container>
                <HHGrid
                  item
                  xs={0}
                  alignContent={'center'}
                  display={'flex'}
                  flexWrap={'inherit'}
                >
                  <HHTypography hhVariant="h2">Criteria</HHTypography>
                </HHGrid>
                <HHGrid item xs={2}>
                  <Menu
                    id="criteria-menu"
                    anchorEl={anchorElement}
                    data-testid="criteria-menu"
                    open={criteriaMenuOpen}
                    onClose={(): void => setCriteriaMenuOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    sx={{
                      '& .MuiMenuItem-root:hover': {
                        backgroundColor: '#D1F0DC',
                      },
                    }}
                  >
                    <MenuItem onClick={handleSelectCriterion}>
                      <DataSaverOnRounded
                        color="primary"
                        sx={{
                          paddingRight: '0.5rem',
                        }}
                      />
                      Criterion
                    </MenuItem>
                    <MenuItem
                      onClick={(): void => setCriterionFormOpen(false)}
                      disabled={true}
                    >
                      <RuleRounded sx={{ paddingRight: '0.5rem' }} /> Rule
                    </MenuItem>
                  </Menu>
                  <IconButton
                    color={'primary'}
                    onClick={handleAddCriteria}
                    data-testId="add-criteria-button"
                  >
                    <Add />
                  </IconButton>
                </HHGrid>
                <HHGrid item xs={8} />
              </HHGrid>
              <HHGrid item xs={12} minHeight={'25vh'}>
                <DataGrid
                  columns={criteriaColumns}
                  data-testid="criteria-grid"
                  rows={criteriaWithId ?? []}
                  hideFooter
                  getRowId={(row): string => row.id}
                  sx={{
                    height: '100%',
                    '& .MuiDataGrid-row:hover': { backgroundColor: '#D1F0DC' },
                    '& .MuiDataGrid-cell:hover': {
                      cursor: 'default',
                    },
                  }}
                  headerHeight={0}
                  components={{
                    NoRowsOverlay: NoRowsMessage,
                  }}
                  disableSelectionOnClick
                  componentsProps={{
                    noRowsOverlay: {
                      title: newRuleFormContent.noCriteria.title,
                      body: newRuleFormContent.noCriteria.body,
                      paddingTop: '7rem',
                    },
                  }}
                />
              </HHGrid>
            </HHGrid>
            <HHGrid
              container
              display={'flex'}
              spacing={2}
              justifyContent={'end'}
              marginTop={'1rem'}
            >
              <HHGrid item xs={8} />
              <HHGrid item xs={0}>
                <HHButton
                  data-testid="cancel-button"
                  type="button"
                  hhVariant="text"
                  sx={{ width: 'fit-content' }}
                  onClick={handleCancelClick}
                >
                  {buttonLabels.cancel}
                </HHButton>
              </HHGrid>
              <HHGrid item xs={0}>
                <HHLoadingButton
                  hhVariant="variant-bypass"
                  variant="contained"
                  sx={{ width: 'fit-content' }}
                  type="submit"
                  loading={updateRuleLoading}
                  disabled={!isFormValid || updateRuleLoading}
                  data-testid="submit-button"
                >
                  Update
                </HHLoadingButton>
              </HHGrid>
            </HHGrid>
          </form>
        </HHGrid>
      </HHGrid>
    )
  );
};
