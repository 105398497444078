import { NewBillableActivityTypeObject } from '../components/contract/package/custom-package-types';
import {
  billableActivitiesMap,
  billableActivitiesMapv2,
} from '../constants/strings/contract/form-constants';
import {
  BillableActivityType,
  EngagementType,
  PackageOfferingsType,
} from '../types';
import {
  capitalizeFirstLetterAndSpaceCamelCaseString,
  capitalizeFirstLetterAndSpaceSnakeString,
} from './bills-utils';

export const isBillableActivitiesWithDetails = (
  bat: BillableActivityType[],
): boolean =>
  bat.some(activity => activity.details && activity.details !== null);

export const isBillableActivityType = (
  bat: number[] | BillableActivityType[],
): bat is BillableActivityType[] =>
  (bat as BillableActivityType[]).every(
    (ba: BillableActivityType) => ba.id !== undefined,
  );

export const normalizeBillableActivityTypesForComparison = (
  bat: number[] | NewBillableActivityTypeObject | BillableActivityType[],
): NewBillableActivityTypeObject | number[] => {
  if (!Array.isArray(bat)) {
    return bat;
  }

  if (!isBillableActivityType(bat)) {
    return bat.toSorted((a, b) => a - b);
  }

  if (isBillableActivitiesWithDetails(bat)) {
    return bat.reduce((acc: NewBillableActivityTypeObject, activity) => {
      acc[activity.engagementTypeId] = activity.details;
      return acc;
    }, {});
  }
  return bat.map(activity => activity.engagementTypeId).sort((a, b) => a - b);
};

/**
 * @param programIndications
 *split into x number of arrays based on first word in indication,
 *return object of arrays:
 * @returns Record<string, PackageOfferingType>[]
 */
export const categorizePackageIndications = (
  packageOfferings: PackageOfferingsType[],
): Record<string, PackageOfferingsType[]> =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  packageOfferings.reduce(
    (acc: Record<string, PackageOfferingsType[]>, curr) => {
      const splitPi = curr.programIndication?.split('_') ?? [];
      if (splitPi.length === 0) {
        return acc;
      }

      const program = splitPi[0];
      return {
        ...acc,
        [program]: [...(acc[program] ?? []), curr],
      };
    },
    {},
  );

/**
 * @param indicationList
 * i.e [{programIndication: 'chronic_pelvic_pain'}, {programIndication: 'chronic_back'}]
 * @returns string
 * i.e 'Pelvic Pain, Back'
 **/
export const listifyIndications = (
  indicationList: Pick<PackageOfferingsType, 'programIndication'>[],
): string =>
  indicationList
    .map(val =>
      capitalizeFirstLetterAndSpaceSnakeString(
        val.programIndication?.split('_').splice(1).join('_') ?? '',
      ),
    )
    .join(', ');

/**
 *
 * @param ba string | BillableActivityType | EngagementType
 * @returns string
 * take in a string 'enso_session' or {id: 1, name: 'enso_session', details: {dailyCap: 1}}
 * returns 'Enso session' or 'Enso session: Daily Cap 1'
 */
export const getBillableActivityDisplayString = (
  ba: string | BillableActivityType | EngagementType,
): string => {
  if (typeof ba !== 'string') {
    if ('details' in ba && ba.details) {
      const activityName = billableActivitiesMap[ba.name];
      const detail = ba.details
        ? `: ${capitalizeFirstLetterAndSpaceCamelCaseString(Object.keys(ba.details)[0])} ${ba.details[Object.keys(ba.details)[0]]}`
        : '';
      return `${activityName}${detail}`;
    } else {
      return billableActivitiesMapv2[ba.name];
    }
  }
  return billableActivitiesMapv2[ba];
};
