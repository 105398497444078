import {
  HHBox,
  HHButton,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { isAfter, subHours } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { DateRangePickerValue } from '../../components/date-range-picker';
import { buttonLabels } from '../../constants/hinge-connect-constants.constant';
import { useModal } from '../../hooks/use-modal';
import {
  assessmentTasksTabContent,
  AssessmentTaskStatus,
} from '../constants/assessment-tasks.constant';
import {
  AssessmentTaskListItem,
  ListAssessmentTasksQuery,
  useListAssessmentTasksQuery,
} from '../types';
import { AssessmentTasksToolbar } from './assessment-tasks-toolbar';
import { CancelAssessmentTaskModal } from './cancel-assessment-task-modal';
import { CreateAssessmentTaskModal } from './create-assessment-task-modal';
import { ListAssessmentTasksGrid } from './list-assessment-tasks-grid';

interface ListAssessmentTasksViewContentProps {
  data: ListAssessmentTasksQuery | undefined;
  filteredAssessmentTasks: AssessmentTaskListItem[] | undefined;
  loading: boolean;
  refetch: () => void;
  selectedId: string;
  openCancellationModal: (id: string) => void;
}

const DEFAULT_POLL_INTERVAL = 5000;

type ListAssessmentTasksViewProps = {
  pollInterval?: number;
};

const IN_PROGRESS_STATUSES: string[] = [
  AssessmentTaskStatus.IN_PROGRESS,
  AssessmentTaskStatus.QUEUED,
];

const ListAssessmentTasksViewContent = ({
  filteredAssessmentTasks,
  loading,
  openCancellationModal,
}: ListAssessmentTasksViewContentProps): JSX.Element => (
  <ListAssessmentTasksGrid
    assessmentTasks={filteredAssessmentTasks || []}
    openCancellationModal={openCancellationModal}
    loading={loading}
  />
);

export const ListAssessmentTasksView = ({
  pollInterval = DEFAULT_POLL_INTERVAL,
}: ListAssessmentTasksViewProps): JSX.Element => {
  const {
    isOpen: createAssessmentTaskModalOpen,
    handleOpen: openCreateAssessmentTaskModal,
    handleClose: closeCreateAssessmentTaskModal,
  } = useModal();

  const {
    isOpen: cancelAssessmentTaskModalOpen,
    handleOpen: openCancelAssessmentTaskModal,
    handleClose: closeCancelAssessmentTaskModal,
  } = useModal();

  const [selectedId, setSelectedId] = useState('');

  const [selectedStatus, setSelectedStatus] = useState<
    AssessmentTaskStatus | ''
  >('');
  const [dateRange, setDateRange] = useState<
    [DateRangePickerValue, DateRangePickerValue]
  >([null, null]);
  const isPolling = useRef<boolean>(false);

  const setIdAndOpenCancellationModal = (id: string): void => {
    setSelectedId(id);
    openCancelAssessmentTaskModal();
  };

  const { data, loading, refetch, startPolling, stopPolling } =
    useListAssessmentTasksQuery({
      variables: {
        listAssessmentTasksInput: {
          limit: 50,
          startDate: dateRange[0]
            ? dateRange[0].toISOString().split('T')[0]
            : null,
          endDate: dateRange[1]
            ? dateRange[1].toISOString().split('T')[0]
            : null,
        },
      },
    });

  const [filteredAssessmentTasks, setFilteredAssessmentTasks] = useState<
    AssessmentTaskListItem[] | undefined
  >(data?.listAssessmentTasks?.assessmentTasks);

  useEffect(() => {
    if (data?.listAssessmentTasks?.assessmentTasks) {
      const filteredTasks = data?.listAssessmentTasks?.assessmentTasks.filter(
        (task: AssessmentTaskListItem) =>
          !selectedStatus || task.status === selectedStatus,
      );
      const hasRefreshableTask = filteredTasks.some(task => {
        const twelveHoursAgo = subHours(new Date(), 12);
        const updatedRecently = isAfter(
          new Date(task.updatedAt),
          twelveHoursAgo,
        );
        return updatedRecently && IN_PROGRESS_STATUSES.includes(task.status);
      });
      if (hasRefreshableTask && !isPolling.current) {
        startPolling(pollInterval);
        isPolling.current = true;
      }
      if (!hasRefreshableTask && isPolling.current) {
        stopPolling();
        isPolling.current = false;
      }
      setFilteredAssessmentTasks(filteredTasks);
    }
  }, [data, selectedStatus, startPolling, stopPolling, pollInterval]);

  return (
    <HHBox paddingY="1rem">
      <CreateAssessmentTaskModal
        isOpen={createAssessmentTaskModalOpen}
        handleClose={closeCreateAssessmentTaskModal}
      />

      <CancelAssessmentTaskModal
        id={selectedId}
        isOpen={cancelAssessmentTaskModalOpen}
        handleClose={closeCancelAssessmentTaskModal}
      />

      <HHStack direction="row" justifyContent="space-between">
        <HHTypography hhVariant="h2">
          {assessmentTasksTabContent.listView.title}
        </HHTypography>
        <HHButton
          hhVariant="variant-bypass"
          variant="contained"
          onClick={openCreateAssessmentTaskModal}
        >
          {buttonLabels.new}
        </HHButton>
      </HHStack>
      <AssessmentTasksToolbar
        {...{
          dateRange,
          setDateRange,
          selectedStatus,
          setSelectedStatus,
        }}
      />
      <HHBox marginTop="1rem" textAlign="center">
        <ListAssessmentTasksViewContent
          data={data}
          filteredAssessmentTasks={filteredAssessmentTasks}
          loading={loading}
          refetch={refetch}
          selectedId={selectedId}
          openCancellationModal={setIdAndOpenCancellationModal}
        />
      </HHBox>
    </HHBox>
  );
};
