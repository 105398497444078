import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { useState } from 'react';

interface ParserSectionProps {
  parserType: string;
  setParserType: (value: string) => void;
  encrypted: boolean;
  setEncrypted: (value: boolean) => void;
  delimiterChar: string;
  setDelimiterChar: (value: string) => void;
  errors: { [key: string]: string };
}

export const ParserSection = ({
  parserType,
  setParserType,
  encrypted,
  setEncrypted,
  delimiterChar,
  setDelimiterChar,
}: ParserSectionProps): JSX.Element => {
  const [error, setError] = useState(false);
  return (
    <Grid container spacing={2} mt={2} alignItems="center">
      <Grid item xs={12} sm={2}>
        <TextField
          select
          label="Parser Type"
          required
          value={parserType}
          onChange={(e): void => setParserType(e.target.value)}
          fullWidth
          InputProps={{
            style: {
              height: '50px',
            },
          }}
        >
          <MenuItem value="Delimiter">Delimiter</MenuItem>
          <MenuItem value="Layout 834">Layout 834</MenuItem>
          <MenuItem value="Fixed Width">Fixed Width</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormControlLabel
          label="Encrypted"
          labelPlacement="start"
          control={
            <Checkbox
              checked={encrypted}
              onChange={(e): void => setEncrypted(e.target.checked)}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={8} />
      {parserType === 'Delimiter' && (
        <Grid item xs={12} sm={2}>
          <TextField
            label="Character"
            value={delimiterChar}
            required
            onChange={(e): void => setDelimiterChar(e.target.value)}
            fullWidth
            error={error}
            onBlur={(): void => setError(!delimiterChar)}
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};
