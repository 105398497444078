import { HHTypography } from '@hinge-health/react-component-library';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import { useConfigsTable } from '../hooks/use-configs-table';
import { ConfigsTable } from './list-file-configs-grid';

export const ListFileConfigsView = (): JSX.Element => {
  const { handleAddClick } = useConfigsTable();

  return (
    <>
      <br />
      <HHTypography hhVariant="h2">File Configs</HHTypography>
      <br />
      <ConfigsTable />
      <Fab
        color="primary"
        size="large"
        aria-label="add"
        onClick={handleAddClick}
        sx={{
          position: 'fixed',
          bottom: 32,
          right: 32,
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
};
