import { Dayjs } from 'dayjs';

import { ContractPackagesInput } from '../../../../../types';
import { NO_DATA_TEMPLATE } from '../../../constants/strings/contract/form-constants';
import {
  BillableActivityType,
  ContractType,
  ContractTypeUnion,
  MilestoneConditionType,
} from '../../../types';
import { ActiveStatus } from '../../../utils/contract-utils';
import {
  BillableActivitiesWithDetails,
  DEFAULT_FORM_CURRENCY,
} from '../package/custom-package-types';
import { CurrencyOption } from './components/currency-select';

export interface ContractFormProps {
  formTitle: string;
  onSubmitCallback: (values: ContractFormValues) => void;
  onCancel: () => void;
  currentContracts: ContractType[] | [];
  submitTitle?: string | null;
  disableModalSubmit?: boolean | null;
  startDateDisablePast?: boolean;
  submissionCount?: number;
  hideVoid?: boolean;
  partnershipIds?: number[];
  isBatch?: boolean;
  wholeFormDisabled?: boolean;
  feeForServiceFormDisabled?: boolean;
  activeStatus?: ActiveStatus;
  disableSubmit?: boolean;
  contractPackageIds?: number[];
  contractEditId?: number;
}

export interface ContractTemplateOption {
  name: string;
  contractTemplateId: number;
  contract: ContractTypeUnion[] | Record<string, unknown>[];
}

export type ContractTypeExtension = ContractTypeUnion & {
  name: string;
  payment: number;
  billableActivityTypes: string[] | BillableActivityType[];
  dateOfServiceOffset?: number;
  dayRange: number[];
  conditions: MilestoneConditionType[];
};

export interface ContractFormValues {
  /** The acute price of the ContractType item */
  acutePrice: number;
  /** Engagement type ids to be set as billable activities */
  billableActivityTypes: BillableActivitiesWithDetails;
  /** The chronic price of the ContractType item */
  chronicPrice: number;
  /** The contract value of the ContractType item */
  contract: Record<string, unknown>[];
  currency: string;
  /** The contract template id of the ContractType item */
  contractTemplateId: number;
  /** The updated date time value of the ContractType item */
  endDate: string | null;
  /** The start date of the ContractType item */
  startDate: string;
  /* Boolean wether contract is void or not */
  void: boolean;
  /** Contract packages */
  contractPackages: ContractPackagesInput[];
  partnershipId?: number | null;
}

export interface RawFormValues {
  currentTemplate: ContractTemplateOption;
  startDate: Date | null;
  endDate: Date | null;
  currency: CurrencyOption;
  chronicCoreCharge: number;
  isVoid: boolean;
  selectedBillableActivities: BillableActivitiesWithDetails;
  contract: ContractTemplateOption['contract'];
  enableAcuteProgram: boolean;
  acuteCoreCharge: number;
  enableInPersonVisit: boolean;
  contractPackages: RawContractPackage[];
  partnershipId: number | null;
}

export interface RawContractPackage {
  id: number | null;
  packageId: number | null;
  billingModelId: number | null;
  price: number;
  startDate: Date | null;
  billableActivities: BillableActivitiesWithDetails;
}

export interface DateState {
  value: Dayjs | null;
  error: string | null;
}

export const DEFAULT_CONTRACT_PACKAGE_VALUES = {
  id: null,
  packageId: null,
  billingModelId: null,
  price: 150.0,
  startDate: null,
  billableActivities: {},
};

export const DEFAULT_LEGACY_FORM_VALUES = {
  currentTemplate: NO_DATA_TEMPLATE,
  startDate: null,
  endDate: null,
  currency: DEFAULT_FORM_CURRENCY,
  chronicCoreCharge: 995,
  isVoid: false,
  selectedBillableActivities: {},
  contract: [{}],
  enableAcuteProgram: false,
  acuteCoreCharge: 0,
  enableInPersonVisit: false,
  contractPackages: [],
  partnershipId: null,
};
