import {
  HHBox,
  HHButton,
  HHSnackbarAlert,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { makeStyles } from '@mui/styles';
import { buttonLabels } from '../../constants/hinge-connect-constants.constant';
import { useModal } from '../../hooks/use-modal';
import { useSnackbar } from '../../hooks/use-snackbar';
import { tagSnackbars, tagsTabContent } from '../constants/tags.constant';
import {
  ListTagsContextProviderProps,
  useListTagsContext,
} from '../contexts/list-tags.context';
import { useDeleteTag } from '../hooks/use-delete-tag';
import { Tag } from '../types';
import { CreateTagModal } from './create-tag-modal';
import { ListTagsGrid } from './list-tags-grid';
import { ListTagsToolbar } from './list-tags-toolbar';
import { NoFilteredTagsMessage } from './no-filtered-tags';
import { NoTagsMessage } from './no-tags';

const useStyles = makeStyles(() => ({
  loadingErrorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10%',
  },
}));

const ListTagsViewContent = ({
  loading,
  filteredTags,
  totalTagCount,
  deleteTag,
  deleteTagLoading,
}: {
  loading: boolean;
  filteredTags: Tag[] | undefined;
  totalTagCount: number;
  deleteTag: ({ variables }: { variables: { id: string } }) => void;
  deleteTagLoading: boolean;
}): JSX.Element => {
  const classes = useStyles();

  if (filteredTags && filteredTags.length < 1) {
    return (
      <div className={classes.loadingErrorContainer}>
        {totalTagCount > 0 ? <NoFilteredTagsMessage /> : <NoTagsMessage />}
      </div>
    );
  }

  return (
    <ListTagsGrid
      tags={filteredTags || []}
      deleteTag={deleteTag}
      deleteTagLoading={deleteTagLoading}
      loading={loading}
    />
  );
};

export const ListTagsViewContainer = (): JSX.Element => {
  const { filteredTags, totalTagCount, listTagsLoading } = useListTagsContext();
  const {
    isOpen: createTagModalOpen,
    handleOpen: openCreateTag,
    handleClose: closeCreateTag,
  } = useModal();

  const {
    deleteTag,
    data: deleteTagData,
    loading: deleteTagLoading,
    error: deleteTagError,
    errorMessages: deleteTagErrorMessages,
  } = useDeleteTag((): void => {
    // do nothing
  });
  const {
    snackbar: deleteTagSnackbar,
    handleClose: handleDeleteTagSnackbarClose,
  } = useSnackbar({
    data: deleteTagData,
    error: deleteTagError,
    errorMessages: deleteTagErrorMessages,
    successMessage: tagSnackbars.delete,
  });

  return (
    <>
      <HHSnackbarAlert
        message={deleteTagSnackbar.message}
        severity={deleteTagSnackbar.severity}
        verticalAlignment="top"
        horizontalAlignment="center"
        hhVariant="standard"
        open={deleteTagSnackbar.open}
        onClose={handleDeleteTagSnackbarClose}
        autoHideTime={
          deleteTagSnackbar.severity === 'success' ? 3000 : undefined
        }
      />
      <CreateTagModal
        isOpen={createTagModalOpen}
        handleClose={closeCreateTag}
      />
      <HHStack direction="row" justifyContent="space-between">
        <HHTypography hhVariant="h2">
          {tagsTabContent.listView.title}
        </HHTypography>
        <HHButton
          hhVariant="variant-bypass"
          variant="contained"
          onClick={openCreateTag}
        >
          {buttonLabels.new}
        </HHButton>
      </HHStack>
      <ListTagsToolbar />
      <HHStack direction="row" justifyContent="space-between">
        <ListTagsViewContent
          loading={listTagsLoading}
          filteredTags={filteredTags}
          totalTagCount={totalTagCount}
          deleteTag={deleteTag}
          deleteTagLoading={deleteTagLoading}
        />
      </HHStack>
    </>
  );
};

export const ListTagsView = ({
  ContextProvider,
}: {
  ContextProvider: ({ children }: ListTagsContextProviderProps) => JSX.Element;
}): JSX.Element => (
  <ContextProvider>
    <HHBox paddingY="1rem">
      <ListTagsViewContainer />
    </HHBox>
  </ContextProvider>
);
