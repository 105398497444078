import {
  HHAlert,
  HHBreadcrumbs,
  HHLink,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BillingLeftPanel from '../components/billing-tool-left-panel';
import { ContractPackageFormValues } from '../components/contract/package/custom-package-types';
import { ContractPackageForm } from '../components/contract/package/form';
import LoadingComponent from '../components/utils/loading';
import { routes } from '../constants/strings/routes';
import { useSnackbarContext } from '../context/snackbar.context';
import { useClientInsurersById } from '../hooks/client-insurer-hook';
import {
  GetContractsByClientInsurerDocument,
  useCreatePackageContractMutation,
  useGetContractsByClientInsurerQuery,
} from '../types';
import { getClientsInsurerPartnership } from '../utils/contract-utils';

export const CLIENT_INSURER_NEW_CONTRACT_PACKAGE_CONTAINER_TEST_ID =
  'client-insurer-new-contract-package-container';

const ClientInsurerNewContractPackage = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const clientInsurerId = parseInt(params.id ?? '0', 10);
  const { setSnackbarState } = useSnackbarContext();

  const { data, loading, error } = useClientInsurersById(clientInsurerId);

  const { data: contractsData, error: contractsError } =
    useGetContractsByClientInsurerQuery({
      variables: {
        id: clientInsurerId,
        includeVoided: true,
      },
    });

  const [
    createPackageContractMutation,
    { data: createData, error: createError, loading: createLoading },
  ] = useCreatePackageContractMutation();

  const createPackageContract = useCallback(
    async createInput => {
      await createPackageContractMutation({
        variables: {
          contractInput: createInput,
        },
        refetchQueries: [
          {
            query: GetContractsByClientInsurerDocument,
            variables: { id: clientInsurerId, includeVoided: true },
          },
        ],
      });
    },
    [createPackageContractMutation, clientInsurerId],
  );

  useEffect(() => {
    if (createData && !createLoading) {
      navigate(`${routes.billing.main}/${clientInsurerId}`);
      setSnackbarState('Contract created successfully', 'success', 4000);
    }
  }, [createData, clientInsurerId, createLoading, navigate, setSnackbarState]);

  useEffect(() => {
    if (createError) {
      setSnackbarState('Failed to create contract', 'error', 4000);
    }
  }, [setSnackbarState, createError]);

  if (!loading && (error || !data)) {
    return (
      <HHAlert
        hhVariant="outlined"
        severity="error"
        children={
          <HHTypography hhVariant="body">
            There was an error loading the client insurer.
          </HHTypography>
        }
      ></HHAlert>
    );
  }

  if (!loading && !data) {
    return (
      <HHStack>
        <HHTypography hhVariant="body">
          Client insurer info not found
        </HHTypography>
      </HHStack>
    );
  }

  const currentContracts = contractsData?.getContracts ?? [];

  const formSubmit = (values: ContractPackageFormValues): void => {
    const { void: _, ...rest } = values;
    const additional = {
      clientsInsurerId: clientInsurerId,
      clientId: data?.client.id,
      insurerId: data?.insurer.id,
      procedureCodes: [],
      packageEnabled: true,
    };

    const contractInput = { ...additional, ...rest };

    createPackageContract(contractInput);
  };

  const handleCancel = (): void => {
    navigate(`${routes.billing.main}/${clientInsurerId}`);
  };

  return (
    <HHStack
      data-testid={CLIENT_INSURER_NEW_CONTRACT_PACKAGE_CONTAINER_TEST_ID}
      direction="row"
      height="100%"
    >
      {data && <BillingLeftPanel data={data} />}
      <HHStack direction="column" paddingLeft={6} spacing={4} flex={2}>
        <HHBreadcrumbs sx={{ paddingTop: 6 }} aria-label="breadcrumb">
          <HHLink color="inherit" href={routes.billing.home} underline="hover">
            Home
          </HHLink>
          <HHLink color="inherit" href={routes.billing.home} underline="hover">
            Billing
          </HHLink>
          <HHLink
            color="inherit"
            underline="hover"
            href={`${routes.billing.main}/${clientInsurerId}`}
          >
            {`Client Insurer ${clientInsurerId}`}
          </HHLink>
          <HHTypography color="text.primary" hhVariant="variant-bypass">
            New
          </HHTypography>
        </HHBreadcrumbs>
        {createError ? (
          <HHAlert hhVariant="outlined" severity="error">
            <HHTypography hhVariant="body">
              Contract not created successfully, please check inputs
            </HHTypography>
          </HHAlert>
        ) : null}
        {loading || createLoading ? (
          <LoadingComponent center />
        ) : (
          <HHStack spacing={4} overflow="auto">
            <HHTypography hhVariant="h2">New Contract</HHTypography>
            {contractsError ? (
              <HHAlert hhVariant="outlined" severity="error">
                <HHTypography hhVariant="body">
                  There was an error loading current contracts, contract create
                  unavailable
                </HHTypography>
              </HHAlert>
            ) : null}
            <ContractPackageForm
              currentContracts={currentContracts}
              onSubmitCallback={formSubmit}
              onCancel={handleCancel}
              disableSubmit={!!contractsError}
              hideVoid
              submitTitle="Create Contract"
              partnershipId={getClientsInsurerPartnership(data)?.id}
            />
          </HHStack>
        )}
      </HHStack>
    </HHStack>
  );
};

export default ClientInsurerNewContractPackage;
