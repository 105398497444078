import {
  HHBaseTable,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import dayjs from 'dayjs';
import { useDynamicDrawerContext } from '../../context/dynamic-drawer.context';
import { BillSubmissionsType } from '../../types';
import { formatCurrency } from '../../utils/currency-helpers';
import MoreDetailsButton from '../utils/more-details-button';
import BillDetails from './bill-details';

export interface BillListProps {
  billsData: BillSubmissionsType[];
  currency: string | null;
}

const BillList = ({ billsData, currency }: BillListProps): JSX.Element => {
  const { setDrawerContent } = useDynamicDrawerContext();
  return (
    <HHStack spacing={1} direction="column">
      <HHTypography hhVariant="subtitle1">Bills</HHTypography>
      {billsData.length === 0 ? (
        <HHTypography hhVariant="muted">No bills</HHTypography>
      ) : (
        <HHBaseTable
          headerRowBottomBorder="1px solid rgba(0, 0, 0, 0.12)"
          bodyRowBorder={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
          bodyTypographyVariant="inherit"
          tableHeaders={[
            { value: 'Id', align: 'left' },
            { value: 'From Date', align: 'left' },
            { value: 'Payment Type', align: 'left' },
            { value: 'Submission Amount', align: 'left' },
            { value: 'Remit Amount', align: 'left' },
            { value: 'Remit payer', align: 'left' },
            { value: '', align: 'left' },
          ]}
          bodyContent={billsData.map(bill => {
            const latestSubmission =
              bill.submissions.length > 0 ? bill.submissions[0] : null;
            const remit =
              latestSubmission && latestSubmission.remits.length > 0
                ? latestSubmission.remits[0]
                : null;
            return {
              id: {
                value: bill.id,
              },
              fromDate: {
                value: dayjs(bill.fromDate).format('L'),
              },
              paymentType: {
                value: bill.paymentType,
                subValue: `Amount: ${
                  Number.isFinite(bill.billAmount)
                    ? formatCurrency(bill.billAmount as number, currency)
                    : '--'
                }`,
              },
              submissionAmount: {
                value: latestSubmission
                  ? formatCurrency(latestSubmission.submissionAmount, currency)
                  : '--',
                subValue: latestSubmission
                  ? dayjs(latestSubmission.submissionDate).format('L')
                  : '',
              },
              remitAmount: {
                value: remit
                  ? formatCurrency(remit.patientPaymentAmount, currency)
                  : '--',
                subValue:
                  remit && remit.paymentDate
                    ? `Payment Date: ${dayjs(remit.paymentDate).format('L')}`
                    : '',
              },
              remitPayer: {
                value: remit ? remit.payer : '--',
                subValue:
                  remit && remit.paymentNumber
                    ? `Payment Number: ${remit.paymentNumber}`
                    : '',
              },
              actions: {
                value: (
                  <MoreDetailsButton
                    onClick={(): void =>
                      setDrawerContent(
                        <BillDetails
                          key={bill.id}
                          billData={bill}
                          currency={currency}
                        />,
                        <HHTypography hhVariant="section-title">
                          Bill Data
                        </HHTypography>,
                      )
                    }
                  />
                ),
              },
            };
          })}
        />
      )}
    </HHStack>
  );
};

export default BillList;
