import { Navigate, Route, Routes } from 'react-router-dom';
import { tabRoutes } from '../../constants/member-data-services-constants.constant';
import { fileConfigRoutes } from '../constants/file-configurations.constant';
import { AddFileIngestionConfig } from './add-file-ingestion-config/add-file-ingestion-config';
import { EditFileIngestionConfig } from './edit-file-ingestion-config';
import { ListFileConfigsView } from './list-file-configs-view';

export const FileConfigTab = (): JSX.Element => (
  <Routes>
    <Route
      path={`/${tabRoutes.fileConfigurations}/${fileConfigRoutes.list}`}
      element={<ListFileConfigsView />}
    />
    <Route
      path={`/${tabRoutes.fileConfigurations}/add`}
      element={<AddFileIngestionConfig />}
    />
    <Route
      path={`/${tabRoutes.fileConfigurations}/:id/edit`}
      element={<EditFileIngestionConfig />}
    />
    <Route
      path="*"
      element={
        <Navigate
          to={`/${tabRoutes.baseRoute}/${tabRoutes.fileConfigurations}/${fileConfigRoutes.list}`}
        />
      }
    />
  </Routes>
);
