import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BillableActivityArrayOrObject: any;
  JSON: any;
  JSONObject: any;
};

export type ActionDefinition = {
  __typename?: 'ActionDefinition';
  actions?: Maybe<Array<Scalars['JSONObject']>>;
  metadata?: Maybe<Scalars['JSONObject']>;
  type: Scalars['String'];
};

export type ActionDefinitionInput = {
  actions?: Maybe<Array<Scalars['JSONObject']>>;
  metadata?: Maybe<Scalars['JSONObject']>;
  type: Scalars['String'];
};

export type ActionResult = {
  __typename?: 'ActionResult';
  log?: Maybe<Scalars['String']>;
  output?: Maybe<Scalars['JSON']>;
  status: ActionStatus;
  type: Scalars['String'];
};

export enum ActionStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export type Admin = {
  __typename?: 'Admin';
  avatarUrl: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  role: Scalars['String'];
  uuid: Scalars['ID'];
};

export type AssessedRule = {
  __typename?: 'AssessedRule';
  actionLog?: Maybe<Array<ActionResult>>;
  assertionLog?: Maybe<Scalars['JSON']>;
  status: AssessmentStatus;
};

export type AssessmentResult = {
  __typename?: 'AssessmentResult';
  actionsExecuted: Scalars['Float'];
  assertionPassed: Scalars['Float'];
  completed: Scalars['Float'];
  createdAt: Scalars['String'];
  errored: Scalars['Float'];
  id: Scalars['String'];
  ruleAssessmentResultId?: Maybe<Scalars['String']>;
};

export enum AssessmentStatus {
  ActionsExecuted = 'ACTIONS_EXECUTED',
  AssertionPassed = 'ASSERTION_PASSED',
  Completed = 'COMPLETED',
  Errored = 'ERRORED',
  TagCreated = 'TAG_CREATED'
}

export type AssessmentTask = {
  __typename?: 'AssessmentTask';
  actionsExecuted: Scalars['Float'];
  arguments: Scalars['JSON'];
  assertionPassed: Scalars['Float'];
  assessmentResults: Array<AssessmentResult>;
  completed: Scalars['Float'];
  createdAt: Scalars['String'];
  errored: Scalars['Float'];
  id: Scalars['ID'];
  remaining: Scalars['Float'];
  status: Scalars['String'];
  totalAssessments: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export type AssessmentTaskList = {
  __typename?: 'AssessmentTaskList';
  assessmentTasks: Array<AssessmentTaskListItem>;
  limit: Scalars['Float'];
  total: Scalars['Float'];
};

export type AssessmentTaskListItem = {
  __typename?: 'AssessmentTaskListItem';
  actionsExecuted: Scalars['Float'];
  arguments: Scalars['JSON'];
  assertionPassed: Scalars['Float'];
  completed: Scalars['Float'];
  createdAt: Scalars['String'];
  errored: Scalars['Float'];
  id: Scalars['ID'];
  remaining: Scalars['Float'];
  status: Scalars['String'];
  totalAssessments: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export type Association = {
  __typename?: 'Association';
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
  typeId: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type BaseUser = {
  __typename?: 'BaseUser';
  bio?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  pathwayProgramIndications?: Maybe<Array<Scalars['String']>>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  stateOfResidence?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
};

export type BatchClientInsurerType = {
  __typename?: 'BatchClientInsurerType';
  billingType?: Maybe<Scalars['String']>;
  client: ClientType;
  id: Scalars['Int'];
  insurer: BatchInsurerType;
  isActive: Scalars['Boolean'];
  partnerships: Array<PartnershipType>;
  termDate?: Maybe<Scalars['String']>;
};

export type BatchContractClientInsurerInput = {
  clientId: Scalars['Float'];
  id: Scalars['Float'];
  insurerId: Scalars['Float'];
};

export type BatchContractResponse = {
  __typename?: 'BatchContractResponse';
  clientInsurerId: Scalars['Int'];
  error: Scalars['String'];
  newContract?: Maybe<ContractType>;
  oldContract?: Maybe<ContractType>;
  success: Scalars['Boolean'];
};

export type BatchInsurerType = {
  __typename?: 'BatchInsurerType';
  eligibilityRef: Scalars['String'];
  id: Scalars['Int'];
  identifier: Scalars['String'];
  name: Scalars['String'];
};

export enum BillStatusEnum {
  Archived = 'ARCHIVED',
  NonSubmittable = 'NON_SUBMITTABLE',
  Submittable = 'SUBMITTABLE',
  SubmittableWithBypass = 'SUBMITTABLE_WITH_BYPASS'
}

export type BillSubmissionsType = {
  __typename?: 'BillSubmissionsType';
  baseCharge: Scalars['Float'];
  billAmount?: Maybe<Scalars['Float']>;
  billingMonth: Scalars['String'];
  claimIdentifier: Scalars['String'];
  clientsInsurerId: Scalars['Float'];
  coverageId?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  fromDate: Scalars['String'];
  id: Scalars['Float'];
  internalHolds: Array<InternalHoldBaseType>;
  pathwayId: Scalars['Float'];
  paymentType: Scalars['String'];
  source: Scalars['String'];
  sourceId: Scalars['Float'];
  submissions: Array<SubmissionRemitsType>;
  subscriptionId: Scalars['Float'];
  toDate: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type BillableActivityType = {
  __typename?: 'BillableActivityType';
  createdAt: Scalars['String'];
  details?: Maybe<Scalars['JSONObject']>;
  engagementTypeId: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type BillingModelType = {
  __typename?: 'BillingModelType';
  createdAt: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum BillingTypes {
  BcbsalInvoiceAuto = 'BCBSAL_INVOICE_AUTO',
  BcbsalInvoiceManual = 'BCBSAL_INVOICE_MANUAL',
  BcbsalInvoiceOff = 'BCBSAL_INVOICE_OFF',
  BcbsmaInvoiceAuto = 'BCBSMA_INVOICE_AUTO',
  BcbsmaInvoiceManual = 'BCBSMA_INVOICE_MANUAL',
  BcbsmaInvoiceOff = 'BCBSMA_INVOICE_OFF',
  BcbsmiWaystarClaimsAuto = 'BCBSMI_WAYSTAR_CLAIMS_AUTO',
  BcbsmiWaystarClaimsManual = 'BCBSMI_WAYSTAR_CLAIMS_MANUAL',
  BcbsmiWaystarClaimsOff = 'BCBSMI_WAYSTAR_CLAIMS_OFF',
  CvsInvoiceAuto = 'CVS_INVOICE_AUTO',
  CvsInvoiceManual = 'CVS_INVOICE_MANUAL',
  CvsInvoiceOff = 'CVS_INVOICE_OFF',
  EsiInvoiceAuto = 'ESI_INVOICE_AUTO',
  EsiInvoiceManual = 'ESI_INVOICE_MANUAL',
  EsiInvoiceOff = 'ESI_INVOICE_OFF',
  InvoiceAuto = 'INVOICE_AUTO',
  InvoiceManual = 'INVOICE_MANUAL',
  InvoiceOff = 'INVOICE_OFF',
  NetsuiteInvoiceAuto = 'NETSUITE_INVOICE_AUTO',
  WaystarClaimsAuto = 'WAYSTAR_CLAIMS_AUTO',
  WaystarClaimsManual = 'WAYSTAR_CLAIMS_MANUAL',
  WaystarClaimsOff = 'WAYSTAR_CLAIMS_OFF'
}

export type BillsPaginatedType = {
  __typename?: 'BillsPaginatedType';
  items: Array<PaginatedBillType>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  size: Scalars['Float'];
  total: Scalars['Float'];
};

export type BulkUpdateBillsInput = {
  billIds: Array<Scalars['Float']>;
  clientInsurerId?: Maybe<Scalars['Float']>;
  note: Scalars['String'];
  statusChange: BillStatusEnum;
};

export type BulkUpdateBillsResponse = {
  __typename?: 'BulkUpdateBillsResponse';
  details: Scalars['String'];
};

export type ClearHoldsInput = {
  billIds: Array<Scalars['Float']>;
  holdTypes?: Array<ClearableHoldTypes>;
};

export type ClearHoldsResponse = {
  __typename?: 'ClearHoldsResponse';
  details: Scalars['String'];
};

export enum ClearableHoldTypes {
  ManualHold = 'MANUAL_HOLD'
}

export type ClientInsurerByClientType = {
  __typename?: 'ClientInsurerByClientType';
  clientsInsurers: Array<ClientInsurerDetailsType>;
  id: Scalars['Int'];
  identifier: Scalars['String'];
  name: Scalars['String'];
};

export type ClientInsurerDetailsType = {
  __typename?: 'ClientInsurerDetailsType';
  billingType?: Maybe<Scalars['String']>;
  client: ClientType;
  id: Scalars['Int'];
  insurer: InsurerBasicType;
  isActive: Scalars['Boolean'];
  partnerships: Array<PartnershipType>;
  termDate?: Maybe<Scalars['String']>;
};

export type ClientInsurerType = {
  __typename?: 'ClientInsurerType';
  acuteBasePrice: Scalars['Float'];
  acuteStartDate?: Maybe<Scalars['String']>;
  apiCode?: Maybe<Scalars['String']>;
  apiOn: Scalars['Boolean'];
  apiRequired: Scalars['Boolean'];
  athena: Scalars['Boolean'];
  autoEligibilityEnabled: Scalars['Boolean'];
  billingType?: Maybe<Scalars['String']>;
  chronicBasePrice: Scalars['Float'];
  client: ClientType;
  coreContractRule: Scalars['String'];
  disableEligibilityAlerts?: Maybe<Scalars['Boolean']>;
  efileRequired: Scalars['Boolean'];
  groupNumbers: Array<GroupNumberType>;
  id: Scalars['Int'];
  ignorePrimary: Scalars['Boolean'];
  insurer: InsurerType;
  isActive: Scalars['Boolean'];
  partnerships: Array<PartnershipType>;
  primaryRequired: Scalars['Boolean'];
  programExceptions: Array<ProgramExceptionsType>;
  termDate?: Maybe<Scalars['String']>;
  validGroupNumbers: Array<ValidGroupNumbersType>;
};

export type ClientType = {
  __typename?: 'ClientType';
  id: Scalars['Int'];
  identifier: Scalars['String'];
  name: Scalars['String'];
};

export type ConsultResultType = {
  __typename?: 'ConsultResultType';
  consultMessage?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  hhUuid: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type ContractPackagesInput = {
  billableActivityTypes?: Maybe<Scalars['BillableActivityArrayOrObject']>;
  billingEnabled?: Maybe<Scalars['Boolean']>;
  billingModelId?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['Float']>;
  contractTemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  packageId: Scalars['Float'];
  price?: Maybe<Scalars['Float']>;
  rules?: Maybe<PackageRulesInput>;
  startDate?: Maybe<Scalars['String']>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ContractPackagesType = {
  __typename?: 'ContractPackagesType';
  billableActivityTypes: Array<BillableActivityType>;
  billingEnabled: Scalars['Boolean'];
  billingModel: BillingModelType;
  billingModelId: Scalars['Int'];
  contractId: Scalars['Int'];
  contractTemplateId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  disabledProgramIndications: Array<DisabledProgramIndication>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastModifiedBy: Scalars['String'];
  package: PackageType;
  packageId: Scalars['Int'];
  price: Scalars['Float'];
  rules?: Maybe<RulesType>;
  startDate?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  void: Scalars['Boolean'];
};

export type ContractSubscriptionType = {
  __typename?: 'ContractSubscriptionType';
  feeForServiceEventCount: Scalars['Float'];
  submissionCount: Scalars['Float'];
  subscriptionCount: Scalars['Float'];
};

export type ContractTemplateType = {
  __typename?: 'ContractTemplateType';
  contract: Array<ContractTypeUnion>;
  contractType?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  partnershipId?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['String'];
};

export type ContractType = {
  __typename?: 'ContractType';
  acutePrice?: Maybe<Scalars['Float']>;
  billableActivityTypes: Array<BillableActivityType>;
  chronicPrice?: Maybe<Scalars['Float']>;
  clientId: Scalars['Float'];
  clientsInsurerId: Scalars['Float'];
  contract?: Maybe<Array<ContractTypeUnion>>;
  contractPackages: Array<ContractPackagesType>;
  contractTemplateId?: Maybe<Scalars['Float']>;
  contractType?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  disabledProgramIndication: Array<DisabledProgramIndication>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  insurerId: Scalars['Float'];
  ironcladId?: Maybe<Scalars['String']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  packageEnabled: Scalars['Boolean'];
  partnershipId?: Maybe<Scalars['Float']>;
  procedureCodes: Array<ProcedureCodesType>;
  startDate: Scalars['String'];
  updatedAt: Scalars['String'];
  userAnnualCap?: Maybe<Scalars['Float']>;
  void: Scalars['Boolean'];
};

export type ContractTypeUnion = EngagementContractType | IndividualMilestonesType | MilestonesType;

export type CptCodes = {
  __typename?: 'CptCodes';
  acuteCptCodes?: Maybe<Array<Scalars['String']>>;
};

export type CreateAssessmentTaskInput = {
  endDate?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  resourceType?: Maybe<Scalars['String']>;
  ruleId?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
};

export type CreateAssociationInput = {
  type: Scalars['String'];
  typeId: Scalars['String'];
};

export type CreateBatchContractInput = {
  acutePrice: Scalars['Float'];
  billableActivityTypes: Scalars['BillableActivityArrayOrObject'];
  chronicPrice: Scalars['Float'];
  clientInsurers: Array<BatchContractClientInsurerInput>;
  contractPackages?: Maybe<Array<ContractPackagesInput>>;
  contractTemplateId: Scalars['Float'];
  disabledProgramIndication?: Maybe<Array<Scalars['String']>>;
  endDate?: Maybe<Scalars['String']>;
  partnershipId?: Maybe<Scalars['Float']>;
  startDate: Scalars['String'];
};

export type CreateContractInput = {
  acutePrice?: Maybe<Scalars['Float']>;
  billableActivityTypes?: Maybe<Scalars['BillableActivityArrayOrObject']>;
  chronicPrice?: Maybe<Scalars['Float']>;
  clientId: Scalars['Float'];
  clientsInsurerId: Scalars['Float'];
  contract?: Maybe<Array<Scalars['JSONObject']>>;
  contractPackages?: Maybe<Array<ContractPackagesInput>>;
  contractTemplateId?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  disabledProgramIndication?: Maybe<Array<Scalars['String']>>;
  endDate?: Maybe<Scalars['String']>;
  insurerId: Scalars['Float'];
  ironcladId?: Maybe<Scalars['String']>;
  packageEnabled?: Maybe<Scalars['Boolean']>;
  partnershipId?: Maybe<Scalars['Float']>;
  procedureCodes: Array<ProcedureCodeInput>;
  startDate: Scalars['String'];
  userAnnualCap?: Maybe<Scalars['Float']>;
};

export type CreateDiscountInput = {
  clientId: Scalars['Float'];
  discount: Scalars['Float'];
  discountType: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['Float']>;
  programId?: Maybe<Scalars['Float']>;
  startDate: Scalars['String'];
  suppressEmptyCharges: Scalars['Boolean'];
};

export type CreateFacetInput = {
  associations?: Maybe<Array<CreateAssociationInput>>;
  description: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CreateRecommendationInput = {
  docId: Scalars['String'];
};

export type CreateRuleAssessmentInput = {
  resource: Scalars['String'];
  resourceId: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  ruleIds?: Maybe<Array<Scalars['String']>>;
  source: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateRuleInput = {
  actions?: Maybe<Array<ActionDefinitionInput>>;
  definition: RuleDefinitionInput;
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  source: Scalars['String'];
};

export type CreateTagInput = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type CreateUserTagInput = {
  occurredAt: Scalars['String'];
  resourceId: Scalars['String'];
  source: Scalars['String'];
  tagName: Scalars['String'];
  userId: Scalars['String'];
};

export type CurrencyCodeType = {
  __typename?: 'CurrencyCodeType';
  value: Scalars['String'];
};

export type CvsResponseType = {
  __typename?: 'CvsResponseType';
  errors?: Maybe<Scalars['JSONObject']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  upstreamResponseContent: Scalars['JSONObject'];
};

export type DagRunRequestInput = {
  conf: Scalars['JSONObject'];
  note?: Maybe<Scalars['String']>;
};

export type DagRunResponseType = {
  __typename?: 'DagRunResponseType';
  dagId: Scalars['String'];
  dagRunId: Scalars['String'];
  logicalDate: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

export type DisabledProgramIndication = {
  __typename?: 'DisabledProgramIndication';
  contractId?: Maybe<Scalars['Float']>;
  contractPackageId?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  programIndicationIdentifier: Scalars['String'];
};

export type DisabledProgramIndicationInput = {
  contractId?: Maybe<Scalars['Float']>;
  contractPackageId?: Maybe<Scalars['Float']>;
  programIndicationIdentifiers: Array<Scalars['String']>;
};

export type DiscountDetail = {
  __typename?: 'DiscountDetail';
  clientId?: Maybe<Scalars['Int']>;
  discount: Scalars['Int'];
  discountDefinitionId: Scalars['Int'];
  discountDetailId: Scalars['Int'];
  discountType: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  insurerId?: Maybe<Scalars['Int']>;
  milestoneDiscount?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['Float']>;
  partnershipId?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['Float']>;
  startDate: Scalars['String'];
  subscriptionTier: Array<Maybe<Scalars['Int']>>;
  suppressEmptyCharges: Scalars['Boolean'];
  tier: Scalars['Int'];
};

export type DiscountUsage = {
  __typename?: 'DiscountUsage';
  discountDetailId?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
  discountSum?: Maybe<Scalars['Float']>;
  totalCount: Scalars['Float'];
};

export type EngagementBaseType = {
  __typename?: 'EngagementBaseType';
  billableCount: Scalars['Float'];
  createdAt: Scalars['String'];
  details: Scalars['JSONObject'];
  eventId?: Maybe<Scalars['Float']>;
  eventType: Scalars['String'];
  eventUuid?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  occurredAt: Scalars['String'];
  programId: Scalars['Float'];
  programIndication: Scalars['String'];
  queuedAt: Scalars['String'];
  sourceType: Scalars['String'];
  userUuid: Scalars['String'];
};

export type EngagementContractType = {
  __typename?: 'EngagementContractType';
  activityTypes: Array<Scalars['String']>;
  educationalTypes: Array<Scalars['String']>;
  engagementRules: Array<Scalars['JSONObject']>;
  matchType: Scalars['String'];
};

export type EngagementType = {
  __typename?: 'EngagementType';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type EngagementsPaginationType = {
  __typename?: 'EngagementsPaginationType';
  items: Array<EngagementBaseType>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  size: Scalars['Float'];
  total: Scalars['Float'];
};

export type EnrollUsersInput = {
  patientUuids: Array<Scalars['ID']>;
  workflowType: Scalars['String'];
};

export type EnrollUsersModel = {
  __typename?: 'EnrollUsersModel';
  enrolledUuids: Array<Scalars['String']>;
  failedUuids: Array<Scalars['String']>;
};

export type Facet = {
  __typename?: 'Facet';
  associations?: Maybe<Array<Association>>;
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
  value: Scalars['String'];
};

export type FacetsList = {
  __typename?: 'FacetsList';
  count: Scalars['Float'];
  facets: Array<Facet>;
};

export type FileConfigurationInput = {
  bucket: Scalars['String'];
  columnMappings: Scalars['JSONObject'];
  description: Scalars['String'];
  encodingDetection: Scalars['Boolean'];
  entityTags: Scalars['JSONObject'];
  fileOperations?: Maybe<Array<Scalars['JSONObject']>>;
  groupOperations?: Maybe<Array<Scalars['JSONObject']>>;
  individualOperations: Array<Scalars['JSONObject']>;
  name: Scalars['String'];
  parser: Scalars['JSONObject'];
  pgpEncrypted?: Maybe<Scalars['Boolean']>;
  provider: Scalars['String'];
  regex: Scalars['String'];
};

export type GetCvsEligibilityInput = {
  clientCode: Scalars['String'];
  dateOfBirth: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender: Scalars['String'];
  inquiryDate?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Float']>;
};

export type GetFileConfigurationFrontendResponse = {
  __typename?: 'GetFileConfigurationFrontendResponse';
  bucket: Scalars['String'];
  columnMappings: Scalars['JSONObject'];
  description: Scalars['String'];
  encodingDetection: Scalars['Boolean'];
  entityTags: Scalars['JSONObject'];
  fileOperations: Array<Scalars['JSONObject']>;
  groupOperations: Array<Scalars['JSONObject']>;
  individualOperations: Array<Scalars['JSONObject']>;
  name: Scalars['String'];
  parser: Scalars['JSONObject'];
  provider: Scalars['String'];
  regex: Scalars['String'];
};

export type GetFileConfigurationsResponseType = {
  __typename?: 'GetFileConfigurationsResponseType';
  items: Array<Scalars['JSONObject']>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  size: Scalars['Float'];
  total: Scalars['Float'];
};

export type GetIndividualOperationsInput = {
  name?: Maybe<Scalars['String']>;
};

export type GroupNumberType = {
  __typename?: 'GroupNumberType';
  eligible: Scalars['Boolean'];
  flagType: Scalars['String'];
  groupNumber: Scalars['String'];
};

export type HealthRecord = {
  __typename?: 'HealthRecord';
  createdAt: Scalars['String'];
  data: Scalars['JSONObject'];
  id: Scalars['ID'];
  origin?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  resourceType: Scalars['String'];
  source: Scalars['String'];
  userId: Scalars['String'];
};

export type HealthRecordLinks = {
  __typename?: 'HealthRecordLinks';
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
};

export type HealthRecordMetadata = {
  __typename?: 'HealthRecordMetadata';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  origin?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  resourceType: Scalars['String'];
  source: Scalars['String'];
  userId: Scalars['String'];
};

export type HealthRecordsList = {
  __typename?: 'HealthRecordsList';
  count: Scalars['Float'];
  healthRecords: Array<HealthRecordMetadata>;
  links: HealthRecordLinks;
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
};

export type HoldDetailsType = {
  __typename?: 'HoldDetailsType';
  clientId?: Maybe<Scalars['Float']>;
  holdType: Scalars['String'];
  insurerId?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
};

export type IndividualMilestonesType = {
  __typename?: 'IndividualMilestonesType';
  billableActivityTypes: Array<Scalars['String']>;
  conditions: Array<MilestoneConditionType>;
  name: Scalars['String'];
  payment: Scalars['Float'];
};

export type InsurerBasicType = {
  __typename?: 'InsurerBasicType';
  eligibilityRef: Scalars['String'];
  id: Scalars['Int'];
  identifier: Scalars['String'];
  name: Scalars['String'];
};

export type InsurerType = {
  __typename?: 'InsurerType';
  eligibilityRef: Scalars['String'];
  id: Scalars['Int'];
  identifier: Scalars['String'];
  memberIdPrefix?: Maybe<Scalars['String']>;
  memberIdRequired: Scalars['Boolean'];
  name: Scalars['String'];
};

export type InternalHoldBaseType = {
  __typename?: 'InternalHoldBaseType';
  billId: Scalars['Float'];
  clearedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  holdType: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type InternalHoldType = {
  __typename?: 'InternalHoldType';
  value: Scalars['String'];
};

export type IronCladFields = {
  __typename?: 'IronCladFields';
  acuteCharge?: Maybe<Scalars['Float']>;
  billableActivityTypes: Array<Scalars['String']>;
  chronicCoreCharge?: Maybe<Scalars['Float']>;
  contractType: Scalars['String'];
  counterpartyName: Scalars['String'];
  paymentStructure: Scalars['String'];
  salesforceAccountId: Scalars['String'];
  salesforceOpportunityId: Scalars['String'];
  sowEffectiveDate?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type IroncladPdf = {
  __typename?: 'IroncladPdf';
  docId: Scalars['String'];
  signedPdf?: Maybe<Scalars['String']>;
};

export type LlmResponse = {
  __typename?: 'LLMResponse';
  acutePrice?: Maybe<Scalars['Float']>;
  billableActivityTypes: Array<Scalars['String']>;
  chronicPrice?: Maybe<Scalars['Float']>;
  contract?: Maybe<Array<MilestonesType>>;
  contractTemplateId?: Maybe<Scalars['Float']>;
};

export type ListAssessmentTasksInput = {
  endDate?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
};

export type ListFacetsInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['String']>;
};

export type ListHealthRecordsInput = {
  endDate?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
};

export type ListRuleAssessmentsInput = {
  limit?: Maybe<Scalars['Float']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type ListRulesInput = {
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<RuleStatus>;
};

export type ListTagsInput = {
  deleted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ListUserTagsInput = {
  facetName?: Maybe<Scalars['String']>;
  occurredFrom?: Maybe<Scalars['String']>;
  occurredTo?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type MappingType = {
  __typename?: 'MappingType';
  options: Array<Scalars['JSONObject']>;
  values: Scalars['JSONObject'];
};

export type MilestoneConditionType = {
  __typename?: 'MilestoneConditionType';
  function: Scalars['String'];
  threshold?: Maybe<Scalars['Float']>;
};

export type MilestonesType = {
  __typename?: 'MilestonesType';
  billableActivityTypes: Array<Scalars['String']>;
  conditions: Array<MilestoneConditionType>;
  dateOfServiceOffset: Scalars['Float'];
  dayRange: Array<Scalars['Float']>;
  name: Scalars['String'];
  payment: Scalars['Float'];
};

export type ModuleType = {
  __typename?: 'ModuleType';
  description: Scalars['String'];
  id: Scalars['Float'];
  identifier: Scalars['String'];
  name: Scalars['String'];
  schema: Scalars['JSONObject'];
  version: Scalars['Float'];
};

export type ModulesResponse = {
  __typename?: 'ModulesResponse';
  items: Array<ModuleType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkUpdateBills: BulkUpdateBillsResponse;
  clearHolds: ClearHoldsResponse;
  createAssessmentTask: AssessmentTask;
  createBatchContract: Array<BatchContractResponse>;
  createContract: ContractType;
  createContractPackage: ContractPackagesType;
  createDisabledProgramIndication: Array<DisabledProgramIndication>;
  createDiscount: Array<DiscountDetail>;
  createFacet: Facet;
  createFileConfiguration: UpsertFileConfigurationResponseType;
  createPackageContract: ContractType;
  createRecommendation: Recommendation;
  createRule: Rule;
  createRuleAssessment: RuleAssessment;
  createTag: Tag;
  createUserTag: UserTag;
  deleteRule: Rule;
  deleteTag: Tag;
  deleteUserTag: UserTag;
  enrollUsers: EnrollUsersModel;
  runClaimsManualWebDag: DagRunResponseType;
  setPhoneForUser: Scalars['Boolean'];
  updateAssessmentTask: AssessmentTask;
  updateContract: ContractType;
  updateContractPackage: ContractPackagesType;
  updateDisabledProgramIndication: Array<DisabledProgramIndication>;
  updateDiscount: Array<DiscountDetail>;
  updateFileConfiguration: UpsertFileConfigurationResponseType;
  updateRecommendation: Recommendation;
  updateRule: Rule;
};


export type MutationBulkUpdateBillsArgs = {
  bulkUpdateBillsInput: BulkUpdateBillsInput;
};


export type MutationClearHoldsArgs = {
  clearHoldsInput: ClearHoldsInput;
};


export type MutationCreateAssessmentTaskArgs = {
  createAssessmentTaskInput: CreateAssessmentTaskInput;
};


export type MutationCreateBatchContractArgs = {
  batchContractInput: CreateBatchContractInput;
};


export type MutationCreateContractArgs = {
  contractInput: CreateContractInput;
};


export type MutationCreateContractPackageArgs = {
  contractPackageInput: ContractPackagesInput;
};


export type MutationCreateDisabledProgramIndicationArgs = {
  disabledProgramIndicationInput: DisabledProgramIndicationInput;
};


export type MutationCreateDiscountArgs = {
  discountInput: CreateDiscountInput;
};


export type MutationCreateFacetArgs = {
  createFacetInput: CreateFacetInput;
};


export type MutationCreateFileConfigurationArgs = {
  createFileConfigurationInput: FileConfigurationInput;
};


export type MutationCreatePackageContractArgs = {
  contractInput: CreateContractInput;
};


export type MutationCreateRecommendationArgs = {
  createRecommendationInput: CreateRecommendationInput;
};


export type MutationCreateRuleArgs = {
  createRuleInput: CreateRuleInput;
};


export type MutationCreateRuleAssessmentArgs = {
  createRuleAssessmentInput: CreateRuleAssessmentInput;
};


export type MutationCreateTagArgs = {
  createTagInput: CreateTagInput;
};


export type MutationCreateUserTagArgs = {
  createUserTagInput: CreateUserTagInput;
};


export type MutationDeleteRuleArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserTagArgs = {
  id: Scalars['String'];
};


export type MutationEnrollUsersArgs = {
  input: EnrollUsersInput;
};


export type MutationRunClaimsManualWebDagArgs = {
  dagRunRequestInput: DagRunRequestInput;
};


export type MutationSetPhoneForUserArgs = {
  hhUuid: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationUpdateAssessmentTaskArgs = {
  id: Scalars['String'];
  updateAssessmentTaskInput: UpdateAssessmentTaskInput;
};


export type MutationUpdateContractArgs = {
  contractId: Scalars['Float'];
  contractInput: UpdateContractInput;
};


export type MutationUpdateContractPackageArgs = {
  contractPackageId: Scalars['Float'];
  contractPackageInput: ContractPackagesInput;
};


export type MutationUpdateDisabledProgramIndicationArgs = {
  disabledProgramIndicationInput: DisabledProgramIndicationInput;
};


export type MutationUpdateDiscountArgs = {
  discountInput: UpdateDiscountInput;
};


export type MutationUpdateFileConfigurationArgs = {
  id: Scalars['Float'];
  updateFileConfigurationInput: FileConfigurationInput;
};


export type MutationUpdateRecommendationArgs = {
  updateRecommendationInput: UpdateRecommendationInput;
};


export type MutationUpdateRuleArgs = {
  id: Scalars['String'];
  updateRuleInput: UpdateRuleInput;
};

export type PackageOfferingsType = {
  __typename?: 'PackageOfferingsType';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  lastModifiedBy: Scalars['String'];
  packageId: Scalars['Int'];
  packageOffering: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  programIndication?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type PackageRulesInput = {
  activationFee?: Maybe<Scalars['Float']>;
  activityFee?: Maybe<Scalars['Float']>;
};

export type PackageType = {
  __typename?: 'PackageType';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  lastModifiedBy: Scalars['String'];
  name: Scalars['String'];
  packageOfferings: Array<PackageOfferingsType>;
  updatedAt: Scalars['String'];
};

export type PaginatedBillType = {
  __typename?: 'PaginatedBillType';
  billAmount?: Maybe<Scalars['Float']>;
  billId: Scalars['Float'];
  claimId: Scalars['String'];
  clientsInsurerId: Scalars['Float'];
  dateOfService: Scalars['String'];
  holdDetails?: Maybe<HoldDetailsType>;
  status: Scalars['String'];
  subscriptionId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type PaginationInput = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

export type PartnershipType = {
  __typename?: 'PartnershipType';
  allowCohortExpansion: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  partnershipType: Scalars['String'];
  requiresStateCredentials: Scalars['Boolean'];
};

export type Pathway = {
  __typename?: 'Pathway';
  indication: Scalars['String'];
  program: Scalars['String'];
  screening: Screening;
  totalPoints: Scalars['Float'];
};

export type PaymentType = {
  __typename?: 'PaymentType';
  code: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ProcedureCodeInput = {
  contractId?: Maybe<Scalars['Float']>;
  cptCode: Scalars['String'];
  modifier1?: Maybe<Scalars['String']>;
  modifier2?: Maybe<Scalars['String']>;
  paymentTypeId: Scalars['Float'];
};

export type ProcedureCodesType = {
  __typename?: 'ProcedureCodesType';
  contractId: Scalars['Float'];
  cptCode: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  modifier1?: Maybe<Scalars['String']>;
  modifier2?: Maybe<Scalars['String']>;
  paymentTypeId: Scalars['Float'];
  paymentTypes: PaymentType;
  updatedAt: Scalars['String'];
};

export type ProgramExceptionsType = {
  __typename?: 'ProgramExceptionsType';
  programName: Scalars['String'];
};

export type ProgramIndicationType = {
  __typename?: 'ProgramIndicationType';
  id: Scalars['Int'];
  identifier: Scalars['String'];
  indicationName: Scalars['String'];
  programId: Scalars['Int'];
  programName: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getAllClients: Array<ClientType>;
  getAllEngagementTypes: Array<EngagementType>;
  getAllInsurers: Array<InsurerType>;
  getAllInternalHoldTypes: Array<InternalHoldType>;
  getAllPackages: Array<PackageType>;
  getAssessmentTask: AssessmentTask;
  getBillingModels: Array<BillingModelType>;
  getBills: BillsPaginatedType;
  getBillsBySubscriptionId: Array<BillSubmissionsType>;
  getClaimsManualWebDagRunStatus: DagRunResponseType;
  getClientInsurerById: ClientInsurerType;
  getClientInsurersByClient: ClientInsurerByClientType;
  getClientInsurersByIds: Array<BatchClientInsurerType>;
  getColumnMappings: MappingType;
  getConsultResultById: ConsultResultType;
  getContractSubscriptions: ContractSubscriptionType;
  getContractTemplates: Array<ContractTemplateType>;
  getContracts: Array<ContractType>;
  getCurrencyCodes: Array<CurrencyCodeType>;
  getCvsEligibility: CvsResponseType;
  getDisabledProgramIndications: Array<DisabledProgramIndication>;
  getDiscountUsage: Array<DiscountUsage>;
  getDiscounts: Array<DiscountDetail>;
  getEngagementsByUserUuid: EngagementsPaginationType;
  getFacet: Facet;
  getFileConfiguration: GetFileConfigurationFrontendResponse;
  getFileConfigurations: GetFileConfigurationsResponseType;
  getHealthRecord: HealthRecord;
  getIndividualOperations: Array<ModuleType>;
  getIroncladPdf: IroncladPdf;
  getParserModules: ModulesResponse;
  getPartnerships: Array<PartnershipType>;
  getPaymentTypes: Array<PaymentType>;
  getPhoneStatusForUser: UserPhoneStatus;
  getProgramIndicationsById: Array<ProgramIndicationType>;
  getRecommendation: Recommendation;
  getRule: Rule;
  getRuleAssessment: RuleAssessment;
  getSubscriptionEngagements: EngagementsPaginationType;
  getSubscriptionHoldsByUser: Array<SubscriptionHoldType>;
  getSubscriptions: SubscriptionsPaginationType;
  getUnbilledBills: UnbilledBillsPaginatedType;
  getUserTag: UserTag;
  getUserUuidById: UserLimitedType;
  getWorkflowsByFilter: WorkflowListPayload;
  listAssessmentTasks: AssessmentTaskList;
  listFacets: FacetsList;
  listHealthRecords: HealthRecordsList;
  listHealthRecordsAtCursor: HealthRecordsList;
  listRuleAssessments: RuleAssessmentsList;
  listRules: RulesList;
  listTags: TagsList;
  listUserTags: UserTagsList;
};


export type QueryGetAssessmentTaskArgs = {
  id: Scalars['String'];
};


export type QueryGetBillsArgs = {
  billAmount?: Maybe<Scalars['Float']>;
  billStatuses?: Maybe<Array<BillStatusEnum>>;
  claimIds?: Maybe<Array<Scalars['String']>>;
  clientInsurerIds?: Maybe<Array<Scalars['Float']>>;
  endDate?: Maybe<Scalars['String']>;
  held?: Maybe<Scalars['Boolean']>;
  internalHoldTypes?: Maybe<Array<Scalars['String']>>;
  page?: Maybe<Scalars['Float']>;
  paymentTypes?: Maybe<Array<Scalars['String']>>;
  size?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['Boolean']>;
  subscriptionIds?: Maybe<Array<Scalars['Float']>>;
  userIds?: Maybe<Array<Scalars['Float']>>;
};


export type QueryGetBillsBySubscriptionIdArgs = {
  subscriptionId: Scalars['Float'];
};


export type QueryGetClaimsManualWebDagRunStatusArgs = {
  dagRunId: Scalars['String'];
};


export type QueryGetClientInsurerByIdArgs = {
  id: Scalars['Float'];
};


export type QueryGetClientInsurersByClientArgs = {
  id: Scalars['String'];
};


export type QueryGetClientInsurersByIdsArgs = {
  billingType?: Maybe<BillingTypes>;
  billingTypes?: Maybe<Array<BillingTypes>>;
  clientIdentifier?: Maybe<Scalars['String']>;
  clientIds?: Maybe<Array<Scalars['Int']>>;
  ids?: Maybe<Array<Scalars['Int']>>;
  insurerIds?: Maybe<Array<Scalars['Int']>>;
  partnershipIds?: Maybe<Array<Scalars['Int']>>;
  scope?: Maybe<ScopeType>;
};


export type QueryGetConsultResultByIdArgs = {
  consultId: Scalars['String'];
};


export type QueryGetContractSubscriptionsArgs = {
  clientsInsurerId?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['Float']>;
  contractPackageIds?: Maybe<Array<Scalars['Int']>>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};


export type QueryGetContractTemplatesArgs = {
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetContractsArgs = {
  billableActivityDisabled?: Maybe<Scalars['Boolean']>;
  clientId?: Maybe<Scalars['Float']>;
  clientsInsurerId?: Maybe<Scalars['Float']>;
  includeVoided?: Maybe<Scalars['Boolean']>;
  insurerId?: Maybe<Scalars['Float']>;
};


export type QueryGetCvsEligibilityArgs = {
  getCvsEligibilityInput: GetCvsEligibilityInput;
};


export type QueryGetDisabledProgramIndicationsArgs = {
  contractId?: Maybe<Scalars['Float']>;
  contractPackageId?: Maybe<Scalars['Float']>;
};


export type QueryGetDiscountUsageArgs = {
  clientId?: Maybe<Scalars['Float']>;
  discountDetailIds?: Maybe<Array<Scalars['Int']>>;
  partnershipId?: Maybe<Scalars['Float']>;
};


export type QueryGetDiscountsArgs = {
  clientId?: Maybe<Scalars['Float']>;
  discountDefinitionId?: Maybe<Scalars['Float']>;
  discountDetailId?: Maybe<Scalars['Float']>;
  discountType?: Maybe<Scalars['String']>;
  inquiryDate?: Maybe<Scalars['String']>;
  insurerId?: Maybe<Scalars['Float']>;
  packageId?: Maybe<Scalars['Float']>;
  partnershipId?: Maybe<Scalars['Float']>;
  partnershipIds?: Maybe<Array<Scalars['Int']>>;
  programId?: Maybe<Scalars['Float']>;
  scopeType?: Maybe<ScopeType>;
};


export type QueryGetEngagementsByUserUuidArgs = {
  endDate?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  startDate: Scalars['String'];
  uuid: Scalars['String'];
};


export type QueryGetFacetArgs = {
  id: Scalars['String'];
};


export type QueryGetFileConfigurationArgs = {
  id: Scalars['Float'];
};


export type QueryGetFileConfigurationsArgs = {
  paginationInput: PaginationInput;
};


export type QueryGetHealthRecordArgs = {
  id: Scalars['String'];
};


export type QueryGetIndividualOperationsArgs = {
  getIndividualOperationsInput: GetIndividualOperationsInput;
};


export type QueryGetIroncladPdfArgs = {
  docId: Scalars['String'];
};


export type QueryGetPartnershipsArgs = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};


export type QueryGetPhoneStatusForUserArgs = {
  hhUuid: Scalars['String'];
};


export type QueryGetProgramIndicationsByIdArgs = {
  programIds?: Maybe<Array<Scalars['Int']>>;
};


export type QueryGetRecommendationArgs = {
  id: Scalars['Float'];
};


export type QueryGetRuleArgs = {
  id: Scalars['String'];
};


export type QueryGetRuleAssessmentArgs = {
  id: Scalars['String'];
};


export type QueryGetSubscriptionEngagementsArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  subscriptionId: Scalars['Float'];
};


export type QueryGetSubscriptionHoldsByUserArgs = {
  userId: Scalars['Float'];
};


export type QueryGetSubscriptionsArgs = {
  includeVoid?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  userId: Scalars['Float'];
};


export type QueryGetUnbilledBillsArgs = {
  clientsInsurerIds?: Maybe<Array<Scalars['Float']>>;
  endDate?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
};


export type QueryGetUserTagArgs = {
  id: Scalars['String'];
};


export type QueryGetUserUuidByIdArgs = {
  id: Scalars['Float'];
};


export type QueryGetWorkflowsByFilterArgs = {
  workflowFiltersInputDto: WorkflowListFiltersInputDto;
  workflowType?: Maybe<Scalars['String']>;
};


export type QueryListAssessmentTasksArgs = {
  listAssessmentTasksInput?: Maybe<ListAssessmentTasksInput>;
};


export type QueryListFacetsArgs = {
  listFacetsInput?: Maybe<ListFacetsInput>;
};


export type QueryListHealthRecordsArgs = {
  listHealthRecordsInput: ListHealthRecordsInput;
};


export type QueryListHealthRecordsAtCursorArgs = {
  cursorPath: Scalars['String'];
};


export type QueryListRuleAssessmentsArgs = {
  listRuleAssessmentsInput?: Maybe<ListRuleAssessmentsInput>;
};


export type QueryListRulesArgs = {
  listRulesInput?: Maybe<ListRulesInput>;
};


export type QueryListTagsArgs = {
  listTagsInput?: Maybe<ListTagsInput>;
};


export type QueryListUserTagsArgs = {
  listUserTagsInput: ListUserTagsInput;
};

export type Recommendation = {
  __typename?: 'Recommendation';
  id: Scalars['Float'];
  ironcladFields?: Maybe<IronCladFields>;
  ironcladId?: Maybe<Scalars['String']>;
  llmResponse?: Maybe<LlmResponse>;
  sfdcFields?: Maybe<SfdcFields>;
  source?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type RemitBaseType = {
  __typename?: 'RemitBaseType';
  createdAt: Scalars['String'];
  icn?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  patientPaymentAmount: Scalars['Float'];
  payer: Scalars['String'];
  paymentAmount: Scalars['Float'];
  paymentDate?: Maybe<Scalars['String']>;
  paymentNumber?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  responseDate: Scalars['String'];
  sourceOfRecord: Scalars['String'];
};

export type Rule = {
  __typename?: 'Rule';
  actions?: Maybe<Array<ActionDefinition>>;
  createdAt: Scalars['String'];
  definition: RuleDefinition;
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  source: Scalars['String'];
  status: RuleStatus;
  updatedAt: Scalars['String'];
  version: Scalars['Float'];
};

export type RuleAssessment = {
  __typename?: 'RuleAssessment';
  assessedRules: Array<AssessedRule>;
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  resourceId: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  updatedAt: Scalars['String'];
  userId: Scalars['String'];
};

export type RuleAssessmentsList = {
  __typename?: 'RuleAssessmentsList';
  ruleAssessments: Array<RuleAssessment>;
  total: Scalars['Float'];
};

export type RuleDefinition = {
  __typename?: 'RuleDefinition';
  criteria: Array<Scalars['JSONObject']>;
  name: Scalars['String'];
  operator: Scalars['String'];
};

export type RuleDefinitionInput = {
  criteria: Array<Scalars['JSONObject']>;
  name: Scalars['String'];
  operator: Scalars['String'];
};

export enum RuleStatus {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type RulesList = {
  __typename?: 'RulesList';
  rules: Array<Rule>;
  total: Scalars['Float'];
};

export type RulesType = {
  __typename?: 'RulesType';
  activationFee?: Maybe<Scalars['Float']>;
  activityFee?: Maybe<Scalars['Float']>;
};

export type SfdcFields = {
  __typename?: 'SFDCFields';
  acutePrice?: Maybe<Scalars['Float']>;
  billableActivityTypes: Array<Scalars['String']>;
  chronicPrice?: Maybe<Scalars['Float']>;
  contract?: Maybe<Array<MilestonesType>>;
  contractTemplateId?: Maybe<Scalars['Float']>;
};

export enum ScopeType {
  Exclusive = 'EXCLUSIVE',
  Inclusive = 'INCLUSIVE'
}

export type Screening = {
  __typename?: 'Screening';
  id: Scalars['String'];
};

export type SubmissionRemitsType = {
  __typename?: 'SubmissionRemitsType';
  billId: Scalars['Float'];
  coverageId?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  id: Scalars['Float'];
  netsuiteId?: Maybe<Scalars['String']>;
  remits: Array<RemitBaseType>;
  status: Scalars['String'];
  submissionAmount: Scalars['Float'];
  submissionDate: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type SubscriptionHoldType = {
  __typename?: 'SubscriptionHoldType';
  clearedAt?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['JSONObject']>;
  firstSeenAt: Scalars['String'];
  hitCount: Scalars['Float'];
  id: Scalars['Float'];
  lastSeenAt: Scalars['String'];
  programId?: Maybe<Scalars['Float']>;
  reason: Scalars['String'];
  userId: Scalars['Float'];
  userUuid: Scalars['String'];
};

export type SubscriptionType = {
  __typename?: 'SubscriptionType';
  clientId: Scalars['Float'];
  clientsInsurerId: Scalars['Float'];
  contractId: Scalars['Float'];
  createdAt: Scalars['String'];
  endsAt: Scalars['String'];
  id: Scalars['Float'];
  pathwayId: Scalars['Float'];
  pathwayUuid: Scalars['String'];
  programId?: Maybe<Scalars['Float']>;
  startsAt: Scalars['String'];
  termDate?: Maybe<Scalars['String']>;
  userId: Scalars['Float'];
  userUuid: Scalars['String'];
  void: Scalars['Boolean'];
  yearCount: Scalars['Float'];
};

export type SubscriptionsPaginationType = {
  __typename?: 'SubscriptionsPaginationType';
  items: Array<SubscriptionType>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  size: Scalars['Float'];
  total: Scalars['Float'];
};

export type Tag = {
  __typename?: 'Tag';
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type TagsList = {
  __typename?: 'TagsList';
  count: Scalars['Float'];
  tags: Array<Tag>;
};

export type Task = {
  __typename?: 'Task';
  adminUuid?: Maybe<Scalars['ID']>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  dueDate?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  selection?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type UnbilledBillType = {
  __typename?: 'UnbilledBillType';
  billsCount: Scalars['Float'];
  clientsInsurerId: Scalars['Float'];
  unbilled?: Maybe<Scalars['Float']>;
};

export type UnbilledBillsPaginatedType = {
  __typename?: 'UnbilledBillsPaginatedType';
  items: Array<UnbilledBillType>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  size: Scalars['Float'];
  total: Scalars['Float'];
};

export type UpdateAssessmentTaskInput = {
  status: Scalars['String'];
};

export type UpdateContractInput = {
  acutePrice?: Maybe<Scalars['Float']>;
  billableActivityTypes?: Maybe<Scalars['BillableActivityArrayOrObject']>;
  chronicPrice?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['Float']>;
  clientsInsurerId?: Maybe<Scalars['Float']>;
  contract?: Maybe<Array<Scalars['JSONObject']>>;
  contractPackages?: Maybe<Array<ContractPackagesInput>>;
  contractTemplateId?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  disabledProgramIndication?: Maybe<Array<Scalars['String']>>;
  endDate?: Maybe<Scalars['String']>;
  insurerId?: Maybe<Scalars['Float']>;
  ironcladId?: Maybe<Scalars['String']>;
  packageEnabled?: Maybe<Scalars['Boolean']>;
  partnershipId?: Maybe<Scalars['Float']>;
  procedureCodes?: Maybe<Array<ProcedureCodeInput>>;
  startDate?: Maybe<Scalars['String']>;
  userAnnualCap?: Maybe<Scalars['Float']>;
  void?: Maybe<Scalars['Boolean']>;
};

export type UpdateDiscountInput = {
  clientId?: Maybe<Scalars['Float']>;
  discount: Scalars['Float'];
  discountDefinitionId: Scalars['Float'];
  discountType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['Float']>;
  programId?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  suppressEmptyCharges?: Maybe<Scalars['Boolean']>;
};

export type UpdateRecommendationInput = {
  id: Scalars['Float'];
  status: Scalars['String'];
};

export type UpdateRuleInput = {
  actions?: Maybe<Array<ActionDefinitionInput>>;
  definition?: Maybe<RuleDefinitionInput>;
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<RuleStatus>;
};

export type UpsertFileConfigurationResponseType = {
  __typename?: 'UpsertFileConfigurationResponseType';
  created_at: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  updated_at: Scalars['String'];
};

export type UserLimitedType = {
  __typename?: 'UserLimitedType';
  uuid?: Maybe<Scalars['ID']>;
};

export type UserPhoneStatus = {
  __typename?: 'UserPhoneStatus';
  auth0: Array<Scalars['String']>;
  basilisk: Array<Scalars['String']>;
  hhUuid: Scalars['String'];
  userService: Array<Scalars['String']>;
};

export type UserTag = {
  __typename?: 'UserTag';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  occurredAt: Scalars['String'];
  resourceId: Scalars['String'];
  source: Scalars['String'];
  tagId: Scalars['Float'];
  tagName: Scalars['String'];
  userId: Scalars['String'];
};

export type UserTagsList = {
  __typename?: 'UserTagsList';
  count: Scalars['Float'];
  userTags: Array<UserTag>;
};

export type ValidGroupNumbersType = {
  __typename?: 'ValidGroupNumbersType';
  groupNumber: Scalars['String'];
};

export type WorkflowError = {
  __typename?: 'WorkflowError';
  error: Scalars['String'];
  fieldName: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type WorkflowListFiltersInputDto = {
  adminUuids?: Maybe<Array<Scalars['ID']>>;
  excludeStates?: Maybe<Array<Scalars['String']>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Float']>;
  pageSize?: Maybe<Scalars['Float']>;
  patientUuids?: Maybe<Array<Scalars['ID']>>;
  useCoverage?: Maybe<Scalars['Boolean']>;
};

export type WorkflowListPayload = {
  __typename?: 'WorkflowListPayload';
  errors: Array<WorkflowError>;
  id: Scalars['String'];
  pageNumber: Scalars['Float'];
  pageSize: Scalars['Float'];
  workflows: Array<WorkflowPayload>;
};

export type WorkflowPayload = {
  __typename?: 'WorkflowPayload';
  adminUuids?: Maybe<Array<Scalars['ID']>>;
  coveringAdmins?: Maybe<Array<Admin>>;
  cptCodes?: Maybe<CptCodes>;
  createdAt: Scalars['String'];
  customFields?: Maybe<Scalars['JSONObject']>;
  id: Scalars['Float'];
  isPastDue?: Maybe<Scalars['Boolean']>;
  lastCompletedAt?: Maybe<Scalars['String']>;
  latestTaskDate?: Maybe<Scalars['String']>;
  latestTaskId?: Maybe<Scalars['Float']>;
  pathway?: Maybe<Pathway>;
  patient?: Maybe<BaseUser>;
  patientUuid: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  progress?: Maybe<Array<Scalars['Float']>>;
  referenceEventId?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  tasks?: Maybe<Array<Task>>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  viewed: Scalars['Boolean'];
};

export type GetCvsEligibilityQueryVariables = Exact<{
  dateOfBirth: Scalars['String'];
  clientCode: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Float']>;
  inquiryDate?: Maybe<Scalars['String']>;
}>;


export type GetCvsEligibilityQuery = { __typename?: 'Query', getCvsEligibility: { __typename?: 'CvsResponseType', isEligible?: boolean | null | undefined, errors?: any | null | undefined, upstreamResponseContent: any } };


export const GetCvsEligibilityDocument = gql`
    query getCvsEligibility($dateOfBirth: String!, $clientCode: String!, $firstName: String!, $lastName: String!, $gender: String!, $externalId: String, $postalCode: String, $userId: Float, $inquiryDate: String) {
  getCvsEligibility(
    getCvsEligibilityInput: {dateOfBirth: $dateOfBirth, clientCode: $clientCode, firstName: $firstName, lastName: $lastName, gender: $gender, externalId: $externalId, postalCode: $postalCode, userId: $userId, inquiryDate: $inquiryDate}
  ) {
    isEligible
    errors
    upstreamResponseContent
  }
}
    `;

/**
 * __useGetCvsEligibilityQuery__
 *
 * To run a query within a React component, call `useGetCvsEligibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCvsEligibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCvsEligibilityQuery({
 *   variables: {
 *      dateOfBirth: // value for 'dateOfBirth'
 *      clientCode: // value for 'clientCode'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      gender: // value for 'gender'
 *      externalId: // value for 'externalId'
 *      postalCode: // value for 'postalCode'
 *      userId: // value for 'userId'
 *      inquiryDate: // value for 'inquiryDate'
 *   },
 * });
 */
export function useGetCvsEligibilityQuery(baseOptions: Apollo.QueryHookOptions<GetCvsEligibilityQuery, GetCvsEligibilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCvsEligibilityQuery, GetCvsEligibilityQueryVariables>(GetCvsEligibilityDocument, options);
      }
export function useGetCvsEligibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCvsEligibilityQuery, GetCvsEligibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCvsEligibilityQuery, GetCvsEligibilityQueryVariables>(GetCvsEligibilityDocument, options);
        }
export type GetCvsEligibilityQueryHookResult = ReturnType<typeof useGetCvsEligibilityQuery>;
export type GetCvsEligibilityLazyQueryHookResult = ReturnType<typeof useGetCvsEligibilityLazyQuery>;
export type GetCvsEligibilityQueryResult = Apollo.QueryResult<GetCvsEligibilityQuery, GetCvsEligibilityQueryVariables>;