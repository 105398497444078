import { Navigate, Route, Routes } from 'react-router-dom';
import { buttonLabels } from '../../../hinge-connect/constants/hinge-connect-constants.constant';
import { rulesTabContent } from '../../../hinge-connect/rules/constants/rules.constant';
import { tabRoutes } from '../../constants/member-data-services-constants.constant';
import { targetedEnrollmentRoutes } from '../constants/targeted-enrollment.constants';
import { EditTaggingRuleForm } from './edit-tagging-rule-form';
import { NewTaggingRuleForm } from './new-tagging-rule-form';
import { TargetedEnrollmentListView } from './targeted-enrollment-list-view';

export const TargetedEnrollmentTab = (): JSX.Element => (
  <Routes>
    <Route
      path={`/${tabRoutes.targetedEnrollment}/${targetedEnrollmentRoutes.list}`}
      element={<TargetedEnrollmentListView />}
    />
    <Route
      path={`/${tabRoutes.targetedEnrollment}/:id/${targetedEnrollmentRoutes.view}`}
      element={<EditTaggingRuleForm />}
    />
    <Route
      path={`/${tabRoutes.targetedEnrollment}/:id/${targetedEnrollmentRoutes.edit}`}
      element={<EditTaggingRuleForm />}
    />
    <Route
      path={`/${tabRoutes.targetedEnrollment}/${targetedEnrollmentRoutes.new}`}
      element={
        <NewTaggingRuleForm
          title={rulesTabContent.createRule.title}
          loading={false}
          buttonText={buttonLabels.create}
          onSubmit={function (): void {
            throw new Error('Function Yet To Be Implemented.');
          }}
        />
      }
    />
    <Route
      path={`/${tabRoutes.targetedEnrollment}/:id/edit`}
      element={<EditTaggingRuleForm />}
    />
    <Route
      path="*"
      element={
        <Navigate
          to={`/${tabRoutes.baseRoute}/${tabRoutes.targetedEnrollment}/${targetedEnrollmentRoutes.list}`}
        />
      }
    />
  </Routes>
);
