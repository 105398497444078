import {
  HHButton,
  HHSnackbarAlert,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Box, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetFileConfigurationQuery,
  useUpdateFileConfigurationMutation,
} from '../../../member-data/types';
import { tabRoutes } from '../../constants/member-data-services-constants.constant';
import { ClientInformationSection } from './add-file-ingestion-config/client-information-section';
import { FileInformationSection } from './add-file-ingestion-config/file-information-section';
import { ParserSection } from './add-file-ingestion-config/parser-section';

const validateRequiredFields = (
  ftpFolder: string,
  fileName: string,
  fileConfigName: string,
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};
  if (!ftpFolder) errors.ftpFolder = 'FTP Folder is required';
  if (!fileName) errors.fileName = 'File Name is required';
  if (!fileConfigName) errors.fileConfigName = 'File Config Name is required';
  return errors;
};

export const EditFileIngestionConfig = (): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useGetFileConfigurationQuery({
    variables: { id: id ? parseFloat(id) : 0 },
  });
  const [updateFileConfiguration] = useUpdateFileConfigurationMutation();

  const [fileType, setFileType] = useState('Supplemental');
  const [contentType, setContentType] = useState('Standard');
  const [ftpFolder, setFtpFolder] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileConfigName, setFileConfigName] = useState('');
  const [partnership, setPartnership] = useState('');
  const [client, setClient] = useState('');
  const [insurer, setInsurer] = useState('');
  const [parserType, setParserType] = useState('');
  const [encrypted, setEncrypted] = useState(false);
  const [delimiterChar, setDelimiterChar] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [externalId, setExternalId] = useState('');
  const [provider, setProvider] = useState('defaultProvider');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [alert, setAlert] = useState<{
    type: 'success' | 'error';
    message: string;
  } | null>(null);
  const [initialData, setInitialData] = useState<unknown>(null);

  useEffect(() => {
    if (data) {
      const config = data.getFileConfiguration;
      setFileConfigName(config.name);
      setProvider(config.provider);
      setFtpFolder(config.bucket);
      setFileName(config.regex);
      setEncrypted(!!config.pgpEncrypted);
      setDelimiterChar(config.parser.delimiter);
      setFirstName(config.columnMappings.first_name);
      setLastName(config.columnMappings.last_name);
      setDob(config.columnMappings.dob);
      setExternalId(config.columnMappings.external_id);
      setPartnership(config.entityTags.partnership);
      setClient(config.entityTags.client);
      setInsurer(config.entityTags.insurer);
      setParserType(
        config.parser.name === 'DelimitedFileParser'
          ? 'Delimiter'
          : config.parser.name,
      );
      setInitialData(config);
    }
  }, [data]);

  const handleCancel = (): void => {
    navigate(`/${tabRoutes.baseRoute}/${tabRoutes.fileConfigurations}/list`);
  };

  useEffect(() => {
    let newErrors: { [key: string]: string } = {};

    newErrors = {
      ...newErrors,
      ...validateRequiredFields(ftpFolder, fileName, fileConfigName),
    };

    setErrors(newErrors);
    setIsFormValid(
      Object.keys(newErrors).length === 0 && fileConfigName.trim() !== '',
    );
  }, [ftpFolder, fileName, fileConfigName]);

  const handleSave = async (): Promise<void> => {
    const payload = {
      name: fileConfigName,
      description: '',
      bucket: ftpFolder,
      regex: fileName,
      encodingDetection: true,
      pgpEncrypted: encrypted,
      parser: {
        name: parserType === 'Delimiter' ? 'DelimitedFileParser' : parserType,
        delimiter: delimiterChar,
        skip_header: 'false',
        skip_footer: 'false',
        trim_header_fields_whitespace: 'false',
        trim_record_fields_whitespace: 'false',
        set_header_fields_case: 'none',
        set_record_fields_case: 'none',
      },
      columnMappings: {
        first_name: firstName,
        last_name: lastName,
        dob,
        external_id: externalId,
      },
      provider,
      entityTags: {
        partnership,
        client,
        insurer,
        provider,
      },
      individualOperations: [],
      fileOperations: [],
      groupOperations: [],
    };

    try {
      await updateFileConfiguration({
        variables: { id: id ? parseFloat(id) : 0, data: payload },
      });
      setAlert({
        type: 'success',
        message: 'File configuration updated successfully',
      });
      setTimeout(() => {
        setAlert(null);
        navigate('/member-data-services/file-configurations/list', {
          state: { refresh: true },
        });
      }, 3000);
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Error updating file configuration';
      setAlert({
        type: 'error',
        message: errorMessage,
      });
      setTimeout(() => setAlert(null), 3000);
      console.error('Error updating file configuration:', error);
    }
  };

  const hasChanges = (): boolean => {
    if (!initialData) return false;
    return (
      initialData.name !== fileConfigName ||
      initialData.provider !== provider ||
      initialData.bucket !== ftpFolder ||
      initialData.regex !== fileName ||
      initialData.pgpEncrypted !== encrypted ||
      initialData.parser.delimiter !== delimiterChar ||
      initialData.columnMappings.first_name !== firstName ||
      initialData.columnMappings.last_name !== lastName ||
      initialData.columnMappings.dob !== dob ||
      initialData.columnMappings.external_id !== externalId ||
      initialData.entityTags.partnership !== partnership ||
      initialData.entityTags.client !== client ||
      initialData.entityTags.insurer !== insurer
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading file configuration</div>;

  return (
    <Box p={3}>
      <HHTypography hhVariant="h2">Edit File Ingestion Config</HHTypography>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={2}>
          <TextField
            label="File Config Name"
            value={fileConfigName}
            onChange={(e): void => setFileConfigName(e.target.value)}
            fullWidth
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
      </Grid>
      <br />
      <HHTypography hhVariant="h1">File Information</HHTypography>
      <FileInformationSection
        fileType={fileType}
        setFileType={setFileType}
        contentType={contentType}
        setContentType={setContentType}
        ftpFolder={ftpFolder}
        setFtpFolder={setFtpFolder}
        fileName={fileName}
        setFileName={setFileName}
        errors={errors}
        provider={provider}
        setProvider={setProvider}
      />
      <br />
      <HHTypography hhVariant="h1" mt={2}>
        Client Information
      </HHTypography>
      <ClientInformationSection
        partnership={partnership}
        setPartnership={setPartnership}
        client={client}
        setClient={setClient}
        insurer={insurer}
        setInsurer={setInsurer}
        errors={errors}
      />
      <br />
      <HHTypography hhVariant="h1" mt={2}>
        Parser
      </HHTypography>
      <ParserSection
        parserType={parserType}
        setParserType={setParserType}
        encrypted={encrypted}
        setEncrypted={setEncrypted}
        delimiterChar={delimiterChar}
        setDelimiterChar={setDelimiterChar}
        errors={errors}
      />
      <br />
      <Box mt={4} display="flex" justifyContent="flex-end">
        <HHButton onClick={handleCancel} hhVariant={'text'}>
          Cancel
        </HHButton>
        <Box ml={2}>
          <HHButton
            disabled={!isFormValid || !hasChanges()}
            hhVariant="contained"
            onClick={handleSave}
          >
            Save
          </HHButton>
        </Box>
      </Box>
      {alert && (
        <HHSnackbarAlert
          open={true}
          onClose={(): void => setAlert(null)}
          severity={alert.type}
          autoHideDuration={3000}
          verticalAlignment="top"
          horizontalAlignment="center"
          sx={{ width: '300px' }}
          hhVariant={'standard'}
          message={alert.message}
        ></HHSnackbarAlert>
      )}
    </Box>
  );
};
