import { Cancel, CheckCircle } from '@mui/icons-material';
import { Grid, TextField } from '@mui/material';
import { red } from '@mui/material/colors';
import { useState } from 'react';

export const FileNamePattern = (props: {
  setFileRegexPattern: (pattern: string) => void;
  fileRegexPattern?: string;
}): JSX.Element => {
  const [fileExample, setFileExample] = useState(() => props.fileRegexPattern);
  const [regex, setRegex] = useState('');
  const [testFileName, setTestFileName] = useState('');
  const [testResult, setTestResult] = useState('');
  const [testFileNameMatches, setTestFileNameMatches] =
    useState<RegExpMatchArray | null>();
  const fileNamePatterns = {
    yyyy: '(?P<pub_year>\\d{4})',
    yy: '(?P<pub_year>\\d{2})',
    mm: '(?P<pub_month>\\d{2})',
    dd: '(?P<pub_day>\\d{2})',
    '<client>': '(?P<client>\\w+)',
    '<insurer>': '(?P<insurer>\\w+)',
    '<partnership>': '(?P<partnership>\\w+)',
  };
  const generateRegex = (example: string): void => {
    let fileExampleString = example;
    Object.keys(fileNamePatterns).forEach(pattern => {
      const regexPattern = new RegExp(pattern);
      if (regexPattern.test(example)) {
        fileExampleString = fileExampleString.replace(
          regexPattern,
          fileNamePatterns[pattern as keyof typeof fileNamePatterns],
        );
      }
      setRegex(fileExampleString);
      props.setFileRegexPattern(fileExampleString);
    });
  };

  const testPattern = (fileName: string): void => {
    // We have to replace the ?P with ? to because we are building a regex pattern for the backend python service
    // and are testing it in the frontend with javascript regex. They are of course different. Because reasons.
    const regexPattern = new RegExp(regex.replaceAll('?P', '?'));
    setTestFileNameMatches(fileName.match(regexPattern));
    setTestResult(regexPattern.test(fileName) ? 'Match' : 'No Match');
  };

  const setResultIcon = (): JSX.Element => {
    if (testResult === 'Match') return <CheckCircle color="primary" />;
    if (testResult === 'No Match') return <Cancel sx={{ color: red[500] }} />;
    return <></>;
  };

  const handleFileExampleChange = (example: string): void => {
    setFileExample(example);
    generateRegex(example);
    setTestResult('');
  };

  const handleTestFileNameChange = (fileName: string): void => {
    setTestFileName(fileName);
    testPattern(fileName);
  };

  const testFileNameMatchesString = (): string => {
    if (!testFileNameMatches) return '';
    let testFileNameMatchesString = 'Matched Groups:';
    for (const key in testFileNameMatches.groups) {
      testFileNameMatchesString += `${key}: ${testFileNameMatches.groups[key]} `;
    }
    return testFileNameMatchesString;
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="File Pattern Example"
            value={fileExample}
            onChange={(e): void => handleFileExampleChange(e.target.value)}
            fullWidth
            required
            helperText={regex ? `regex: ${regex}` : ''}
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Test File Name"
            value={testFileName}
            disabled={!regex}
            onFocus={(): void => {
              if (testFileName) testPattern(testFileName);
            }}
            error={testResult === 'No Match'}
            helperText={testFileNameMatchesString()}
            onChange={(e): void => handleTestFileNameChange(e.target.value)}
            fullWidth
            InputProps={{
              style: {
                height: '50px',
              },
              endAdornment: setResultIcon(),
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};
