import {
  HHDivider,
  HHInfoGrid,
  HHStack,
  HHTooltip,
  HHTypography,
} from '@hinge-health/react-component-library';
import InfoIcon from '@mui/icons-material/Info';
import dayjs from 'dayjs';
import { useBillingSetting } from '../../context/billing.context';
import { BillSubmissionsType } from '../../types';
import { formatCurrency } from '../../utils/currency-helpers';
import { displayDateTime } from '../../utils/date-helper';

interface BillDetailsProps {
  billData: BillSubmissionsType;
  currency: string | null;
}

const BillDetails = ({ billData, currency }: BillDetailsProps): JSX.Element => {
  const { value: displayLocal } = useBillingSetting('displayDateTimeLocal');

  return (
    <HHStack spacing={4} padding={1}>
      <HHInfoGrid
        data={[
          {
            content: String(billData.id),
            heading: 'Id',
            copyable: true,
          },
          {
            content: String(billData.pathwayId),
            heading: 'Pathway Id',
            copyable: true,
          },
          {
            content: billData.claimIdentifier,
            heading: 'Claim Identifier',
            copyable: true,
          },
          {
            content: billData.paymentType,
            heading: 'Payment Type',
            copyable: true,
          },
          {
            content: formatCurrency(billData.baseCharge, currency),
            heading: 'Base Charge',
            copyable: false,
          },
          {
            content: Number.isFinite(billData.billAmount)
              ? formatCurrency(billData.billAmount as number, currency)
              : '--',
            heading: 'Bill Amount',
            copyable: false,
          },
          {
            content: displayDateTime(billData.createdAt, {
              displayLocal,
            }),
            heading: 'Created At',
            copyable: false,
          },
        ]}
        divided={false}
        justifyBetween={true}
      />
      <HHStack spacing={4}>
        <HHTypography hhVariant="subtitle1">Internal Holds</HHTypography>
        {billData.internalHolds.length > 0 ? (
          billData.internalHolds.map(ih => (
            <HHStack
              spacing={1}
              key={ih.holdType}
              bgcolor={(theme): string => theme.palette.background.default}
              padding={2}
              borderRadius={3}
            >
              <HHStack spacing={2} alignItems="center" direction="row">
                <HHTypography
                  hhVariant="variant-bypass"
                  variant="h5"
                  textTransform="capitalize"
                >
                  {ih.holdType.replaceAll('_', ' ')}
                </HHTypography>
                {ih.note && (
                  <HHTooltip title={ih.note} hhVariant="bottom">
                    <InfoIcon color="info" />
                  </HHTooltip>
                )}
              </HHStack>
              <HHInfoGrid
                data={[
                  {
                    content: displayDateTime(ih.createdAt, {
                      displayLocal,
                    }),
                    heading: 'Created At',
                    copyable: false,
                  },
                  {
                    content: ih.clearedAt
                      ? displayDateTime(ih.clearedAt, {
                          displayLocal,
                        })
                      : '--',
                    heading: 'Cleared At',
                    copyable: false,
                  },
                ]}
                divided={false}
                justifyBetween={true}
              />
            </HHStack>
          ))
        ) : (
          <HHTypography hhVariant="muted">No internal holds</HHTypography>
        )}
      </HHStack>
      <HHStack spacing={4}>
        <HHTypography hhVariant="subtitle1">Submissions</HHTypography>
        {billData.submissions.length > 0 ? (
          billData.submissions.map(sub => (
            <HHStack
              spacing={1}
              key={sub.id}
              bgcolor={(theme): string => theme.palette.background.default}
              padding={2}
              borderRadius={2}
            >
              <HHTypography
                hhVariant="variant-bypass"
                variant="h5"
                textTransform="capitalize"
              >
                Submission Id - {sub.id}
              </HHTypography>
              <HHInfoGrid
                data={[
                  {
                    content: String(sub.transactionId),
                    heading: 'Transaction Id',
                    copyable: true,
                  },
                  {
                    content: sub.status,
                    heading: 'Status',
                    copyable: false,
                  },
                  {
                    content: formatCurrency(sub.submissionAmount, currency),
                    heading: 'Submission Amount',
                    copyable: false,
                  },
                  {
                    content: dayjs(sub.submissionDate).format('L'),
                    heading: 'Submission Date',
                    copyable: false,
                  },
                  {
                    content: displayDateTime(sub.createdAt, {
                      displayLocal,
                    }),
                    heading: 'Created At',
                    copyable: false,
                  },
                ]}
                divided={false}
                justifyBetween={true}
              />
              <HHTypography hhVariant="subtitle1">Remits</HHTypography>
              {sub.remits.length > 0 ? (
                sub.remits.map(remit => (
                  <HHStack spacing={1} key={remit.id}>
                    <HHInfoGrid
                      data={[
                        {
                          content: formatCurrency(
                            remit.patientPaymentAmount,
                            currency,
                          ),
                          heading: 'Patient Payment',
                          copyable: false,
                        },
                        {
                          content: formatCurrency(
                            remit.paymentAmount,
                            currency,
                          ),
                          heading: 'Payment Amount',
                          copyable: false,
                        },
                        {
                          content: remit.paymentDate
                            ? dayjs(remit.paymentDate).format('L')
                            : '--',
                          heading: 'Payment Date',
                          copyable: false,
                        },
                        {
                          content: remit.icn || '--',
                          heading: 'ICN',
                          copyable: true,
                        },
                        {
                          content: dayjs(remit.responseDate).format('L'),
                          heading: 'Response Date',
                          copyable: false,
                        },
                        {
                          content: displayDateTime(remit.createdAt, {
                            displayLocal,
                          }),
                          heading: 'Created At',
                          copyable: false,
                        },
                      ]}
                      divided={false}
                      justifyBetween={true}
                    />
                    <HHDivider />
                  </HHStack>
                ))
              ) : (
                <HHTypography hhVariant="muted">No remits</HHTypography>
              )}
            </HHStack>
          ))
        ) : (
          <HHTypography hhVariant="muted">No submissions</HHTypography>
        )}
      </HHStack>
    </HHStack>
  );
};

export default BillDetails;
