import {
  HHInfoGrid,
  HHTooltip,
  HHTypography,
} from '@hinge-health/react-component-library';
import InfoIcon from '@mui/icons-material/Info';
import { useBillingSetting } from '../../context/billing.context';
import { displayDateTime } from '../../utils/date-helper';

interface MetaInfoDisplayProps {
  lastModifiedBy?: string;
  updatedAt?: string;
  createdAt?: string;
  floating?: boolean;
}
const MetaInfoDisplay = ({
  lastModifiedBy,
  updatedAt,
  createdAt,
  floating = false,
}: MetaInfoDisplayProps): JSX.Element => {
  const { value: displayLocal } = useBillingSetting('displayDateTimeLocal');
  return (
    <HHTooltip
      title={
        <HHInfoGrid
          data={[
            {
              content: (
                <HHTypography
                  hhVariant="variant-bypass"
                  sx={{ color: 'text.primary', lineBreak: 'anywhere' }}
                >
                  {lastModifiedBy || '--'}
                </HHTypography>
              ),
              heading: 'Modified by',
              copyable: false,
            },
            {
              content: updatedAt
                ? displayDateTime(updatedAt, { displayLocal })
                : '--',
              heading: 'Updated At',
              copyable: false,
            },
            {
              content: createdAt
                ? displayDateTime(createdAt, { displayLocal })
                : '--',
              heading: 'Created At',
              copyable: false,
            },
          ]}
          divided={false}
          justifyBetween={true}
        />
      }
      hhVariant="variant-bypass"
      placement="bottom-start"
      layoutStyles={{
        position: floating ? 'absolute' : 'inherit',
        top: 4,
        right: 4,
      }}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'white',
            border: '1px solid grey',
          },
        },
      }}
    >
      <InfoIcon color="info" />
    </HHTooltip>
  );
};

export default MetaInfoDisplay;
