import { HHSelectOption } from '@hinge-health/react-component-library';
import {
  AutoCompleteOption,
  AutoCompleteOptionWithValidation,
  CriterionOperator,
} from '../rules/constants/types';
import { RuleStatus } from '../rules/types';

export const buttonLabels = {
  new: 'New',
  create: 'Create',
  update: 'Update',
  delete: 'Delete',
  tryAgain: 'Try Again',
  viewAll: 'View All',
  backToList: 'Back to List',
  cancel: 'Cancel',
  confirm: 'Confirm',
  copy: 'Copy',
  run: 'Run',
  close: 'Close',
};

export const tabLabels = {
  rules: 'Rules',
  tags: 'Tags',
  assessmentTasks: 'Assessment Tasks',
  userTags: 'User Tags',
  ruleAssessments: 'Rule Assessments',
  healthRecords: 'Health Records',
};

export const tabRoutes = {
  baseRoute: 'hinge-connect',
  rules: 'rules',
  tags: 'tags',
  assessmentTasks: 'assessment-tasks',
  userTags: 'user-tags',
  ruleAssessments: 'rule-assessments',
  healthRecords: 'health-records',
};

export const titles = {
  pageTitle: 'Hinge Connect',
};

export const genericError = 'An error occurred';

export const notFoundErrorMarker = 'Not Found';

export const datePicker = {
  startDate: 'Start Date',
  endDate: 'End Date',
  to: 'to',
  earliest: 'Earliest',
  latest: 'Latest',
};

export const dataSources = {
  HEALTH_GORILLA: 'HEALTH_GORILLA',
  BCBS_TN: 'BCBS_TN',
  AQ: 'AQ',
  MEMBER_PREDICTIONS: 'MEMBER_PREDICTIONS',
  USER_DEMOGRAPHIC: 'USER_DEMOGRAPHIC',
  BCBS_AL: 'BCBS_AL',
  INSIGHTS: 'INSIGHTS',
  UHC: 'UHC',
  QUANTUMHEALTH: 'QUANTUMHEALTH',
};

export const ruleOperators = {
  ALL: 'all',
  ANY: 'any',
};

export const criteriaOperatorsMap: Map<string, CriterionOperator> = new Map([
  [
    'equals',
    {
      label: 'Equals',
      description:
        'Looks for a value that is strictly equal to the provided value',
    },
  ],
  [
    'contains',
    {
      label: 'Contains',
      values: [
        {
          label: 'Any String',
          value: '#string',
          description: 'Looks for any string to be present',
        },
        {
          label: 'Any Number',
          value: '#number',
          description: 'Looks for any number to be present',
        },
        {
          label: 'Number Greater Than',
          value: '#number>',
          validation: /^#number>\d+$/,
          description: 'Looks for a number greater than the provided value',
        },
        {
          label: 'Number Less Than',
          value: '#number<',
          validation: /^#number<\d+$/,
          description: 'Looks for a number less than the provided value',
        },
        {
          label: 'Number Grater Than or Equal To',
          value: '#number>=',
          validation: /^#number>=\d+$/,
          description:
            'Looks for a number greater than or equal to the provided value',
        },
        {
          label: 'Number Less Than or Equal To',
          value: '#number<=',
          validation: /^#number<=\d+$/,
          description:
            'Looks for a number less than or equal to the provided value',
        },
        {
          label: 'In Range',
          value: '#range(x,y)',
          validation: /^#range\((\d+),(\d+)\)$/,
          description:
            'Looks for a number within the provided range (inclusive). Example: (start, end)',
        },
      ],
      description: 'Looks for a value that contains the provided value',
    },
  ],
  [
    'inMapSet',
    {
      label: 'InMapSet',
      values: [
        {
          label: 'cpt-imaging',
          value: '#key(cpt-imaging)',
          description: 'Looks for cpt-imaging',
        },
        {
          label: 'cpt-invasive-emergency',
          value: '#key(cpt-invasive-emergency)',
          description: 'Looks for cpt-invasive-emergency',
        },
        {
          label: 'cpt-invasive-injection',
          value: '#key(cpt-invasive-injection)',
          description: 'Looks for cpt-invasive-injection',
        },
        {
          label: 'cpt-invasive-surgery',
          value: '#key(cpt-invasive-surgery)',
          description: 'Looks for cpt-invasive-surgery',
        },
        {
          label: 'cpt-modifiers',
          value: '#key(cpt-modifiers)',
          description: 'Looks for cpt-modifiers',
        },
        {
          label: 'cpt-therapy',
          value: '#key(cpt-therapy)',
          description: 'Looks for cpt-therapy',
        },
        {
          label: 'ctp-imaging-codes-with-modifiers',
          value: '#key(ctp-imaging-codes-with-modifiers)',
          description: 'Looks for ctp-imaging-codes-with-modifiers',
        },
        {
          label: 'ctp-injection-codes-with-modifiers',
          value: '#key(ctp-injection-codes-with-modifiers)',
          description: 'Looks for ctp-injection-codes-with-modifiers',
        },
        {
          label: 'ctp-therapy-codes-with-modifiers',
          value: '#key(ctp-therapy-codes-with-modifiers)',
          description: 'Looks for ctp-therapy-codes-with-modifiers',
        },
        {
          label: 'drg-dmc-chronic',
          value: '#key(drg-dmc-chronic)',
          description: 'Looks for drg-dmc-chronic',
        },
        {
          label: 'icd10-msk-chronic-by-region',
          value: '#key(icd10-msk-chronic-by-region)',
          description: 'Looks for icd10-msk-chronic-by-region',
        },
        {
          label: 'icd10-msk-dmc',
          value: '#key(icd10-msk-dmc)',
          description: 'Looks for icd10-msk-dmc',
        },
        {
          label: 'icd10-msk-visit-dx',
          value: '#key(icd10-msk-visit-dx)',
          description: 'Looks for icd10-msk-visit-dx',
        },
        {
          label: 'icd10-procedure',
          value: '#key(icd10-procedure)',
          description: 'Looks for icd10-procedure',
        },
        {
          label: 'icd10-wph',
          value: '#key(icd10-wph)',
          description: 'Looks for icd10-wph',
        },
        {
          label: 'ndc-opioids',
          value: '#key(ndc-opioids)',
          description: 'Looks for ndc-opioids',
        },
        {
          label: 'wcodes-balance',
          value: '#key(wcodes-balance)',
          description: 'Looks for wcodes-balance',
        },
      ],
      description: 'Looks for a value from the inMapSet list',
    },
  ],
]);

export const sourceSelectOptions = Object.values(dataSources).map(item => ({
  displayName: item,
  value: item,
}));

export const sourceAutoCompleteOptions = Object.values(dataSources).map(
  item => ({
    label: item,
    value: item,
  }),
);
export const operatorSelectOptions = Object.values(ruleOperators).map(item => ({
  displayName: item,
  value: item,
}));

export const statusOptions: HHSelectOption[] = Object.values(RuleStatus)
  .filter(status => status !== RuleStatus.Deleted)
  .map(item => ({
    displayName: item,
    value: item,
  }));

export const getCriteriaOperatorValueCompleteOptions = (
  label: string,
): AutoCompleteOptionWithValidation[] => {
  if (criteriaOperatorsMap.has(label)) {
    return (
      criteriaOperatorsMap.get(label)?.values?.map(item => ({
        label: item.label,
        value: item.value,
        validation: item.validation,
      })) ?? ([] as AutoCompleteOptionWithValidation[])
    );
  }
  return [];
};

export const getCriteriaOperatorsCompleteOptions = (): AutoCompleteOption[] =>
  Array.from(criteriaOperatorsMap.keys()).map(item => ({
    label: item,
    value: item,
  })) ?? [];
