import { ContractType } from '../../../types';
import { ActiveStatus } from '../../../utils/contract-utils';
import { CurrencyOption } from '../form/components/currency-select';

export enum ValidBillingModel {
  ENGAGEMENT = 'engagement',
  STANDARD = 'standard',
}

export interface BillableActivityDetails {
  [key: string]: number;
}

export interface BillableActivitiesWithDetails {
  [key: number]: BillableActivityDetails | null;
}

export interface RawInnerPackageFormValues {
  id: number | null;
  billingModelId: number;
  packageId: number;
  price: number;
  initialFee: number;
  activityFee: number;
  selectedBillableActivities: BillableActivitiesWithDetails;
}
export interface RawContractPackageFormValues {
  startDate: Date | null;
  endDate: Date | null;
  userAnnualCap: number;
  currency: CurrencyOption;
  selectedPackages: RawInnerPackageFormValues[];
  isVoid: boolean;
  partnershipId: number | null;
}

export interface ContractPackageFormValues {
  /** The start date of the ContractType item */
  startDate: string;
  /** The updated date time value of the ContractType item */
  endDate: string | null;
  /* Boolean wether contract is void or not */
  void: boolean;
  userAnnualCap: number;
  currency: string;
  contractPackages: ContractInnerPackageFormValues[];
  partnershipId: number | null;
}

export interface ContractPackageFormProps {
  currentContracts: ContractType[];
  activeStatus?: ActiveStatus;
  editId?: number;
  onSubmitCallback: (values: ContractPackageFormValues) => void;
  onCancel: () => void;
  disableSubmit: boolean;
  hideVoid: boolean;
  submitTitle: string;
  wholeFormDisabled?: boolean;
  startDateDisablePast?: boolean;
  partnershipId?: number;
}

export interface PackageFormData {
  selectedPackages: {
    id: number | null;
    packageId: number;
    billingModelId: number;
    price: number;
    initialFee: number;
    activityFee: number;
    selectedBillableActivities: BillableActivitiesWithDetails;
  }[];
  selectedBillableActivities: BillableActivitiesWithDetails;
}

export type NewBillableActivityTypeObject = Record<
  number,
  BillableActivityDetails | null
>;

export interface ContractInnerPackageFormValues {
  id: number | null;
  packageId: number;
  billingModelId: number;
  price: number;
  rules?: PackageRules | null;
  billableActivityTypes: number[] | NewBillableActivityTypeObject;
  startDate: string;
}

export interface PackageRules {
  activationFee: number;
  activityFee: number;
}

export const DEFAULT_FORM_CURRENCY = {
  label: 'USD $',
  value: 'USD',
};

export const DEFAULT_PACKAGE_FORM_VALUES = {
  id: null,
  billingModelId: 0,
  packageId: 0,
  price: 1750.0,
  initialFee: 250.0,
  activityFee: 50.0,
  selectedBillableActivities: {},
};

export const DEFAULT_FORM_VALUES = {
  startDate: null,
  endDate: null,
  userAnnualCap: 1750.0,
  currency: DEFAULT_FORM_CURRENCY,
  isVoid: false,
  partnershipId: null,
  selectedPackages: [DEFAULT_PACKAGE_FORM_VALUES],
};
