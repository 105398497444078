import { Grid, MenuItem, TextField } from '@mui/material';
import { FileNamePattern } from './file-name-pattern';

type FileInformationSectionProps = {
  fileType: string;
  setFileType: (value: string) => void;
  contentType: string;
  setContentType: (value: string) => void;
  ftpFolder: string;
  setFtpFolder: (value: string) => void;
  fileName: string;
  setFileName: (value: string) => void;
  errors: { [key: string]: string };
  provider: string;
  setProvider: (value: string) => void;
};

export const FileInformationSection = (
  props: FileInformationSectionProps,
): JSX.Element => {
  const {
    fileType,
    setFileType,
    contentType,
    setContentType,
    ftpFolder,
    setFtpFolder,
    fileName,
    setFileName,
    errors,
    provider,
    setProvider,
  } = props;

  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={2}>
          <TextField
            disabled // disabling since this is the only option supported for now
            select
            label="File Type"
            value={fileType}
            required
            onChange={(e): void => setFileType(e.target.value)}
            fullWidth
            error={!!errors.fileType}
            helperText={errors.fileType}
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          >
            <MenuItem value="E-File">E-File</MenuItem>
            <MenuItem value="Supplemental">Supplemental</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            disabled // disabling since this is the only option supported for now
            select
            label="Content Type"
            required
            value={contentType}
            onChange={(e): void => setContentType(e.target.value)}
            fullWidth
            error={!!errors.contentType}
            helperText={errors.contentType}
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          >
            <MenuItem value="Standard">Standard</MenuItem>
            <MenuItem value="Multi Client Multi Insurer (MCMI)">
              Multi Client Multi Insurer (MCMI)
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="FTP Folder"
            value={ftpFolder}
            onChange={(e): void => setFtpFolder(e.target.value)}
            fullWidth
            disabled // for the time being this will be the same file and should not be changed. As such, we are disabling this field.
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Provider"
            required
            value={provider}
            onChange={(e): void => setProvider(e.target.value)}
            fullWidth
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FileNamePattern
            setFileRegexPattern={setFileName}
            fileRegexPattern={fileName}
          />
        </Grid>
      </Grid>
    </>
  );
};
