import { HHTooltip } from '@hinge-health/react-component-library';
import { PlayCircleRounded } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useModal } from '../../../hooks/use-modal';
import { actionButtonText } from '../../constants/rules.constant';
import { RunRuleModal } from './run-rule-modal';

export const RunRuleButton = ({
  ruleId,
  runRuleLoading,
}: {
  ruleId: string;
  runRuleLoading: boolean;
}): JSX.Element => {
  const { isOpen, handleOpen, handleClose } = useModal();

  return (
    <>
      <RunRuleModal
        ruleId={ruleId}
        isOpen={isOpen}
        handleClose={handleClose}
        runRuleLoading={runRuleLoading}
        data-testid="run-rule-modal"
      />
      <HHTooltip hhVariant="bottom" title={actionButtonText.run}>
        <GridActionsCellItem
          id="run-rule-button"
          data-testid="run-rule-button"
          label={actionButtonText.run}
          icon={<PlayCircleRounded />}
          color="primary"
          onClick={handleOpen}
          disabled={runRuleLoading}
        />
      </HHTooltip>
    </>
  );
};
