import { GetRuleQuery, RuleStatus } from '../types';

export const rulesTabContent = {
  noRules: {
    title: 'No Rules',
    body: 'There aren\'t any rules yet. Create one using the "New" button.',
  },
  createRule: {
    title: 'New Rule',
  },
  deleteRule: {
    title: 'Confirm',
    bodyPrefix: 'Are you sure you want to delete the ',
    bodySuffix: ' rule?',
  },
  updateRule: {
    title: 'Edit Rule',
  },
  ruleLoadError: {
    title: 'Error',
    body: 'We ran into an issue loading that rule. If you continue to see this error, please confirm you are using a valid rule ID in the URL.',
  },
  rulesLoadError: {
    title: 'Woops',
    body: 'We ran into an issue loading rules.',
    button: 'Try Again',
  },
  ruleNotFound: {
    title: 'Rule not found',
    body: 'The rule does not exist, or it might have been deleted.',
  },
  viewRule: {
    title: 'Rule',
  },
  copyRule: {
    title: 'Copy Rule',
    bodyPrefix: 'Are you sure you want to copy ',
    bodySuffix: '?',
    successMessage: 'Rule copied successfully',
  },
  runRule: {
    title: 'Test',
    bodyPrefix: 'Run rule ',
    successMessage: 'Rule ran successfully',
  },
};

export const newRuleFormContent = {
  noCriteria: {
    title: 'No Criteria',
    body: 'Add Criteria to define what the rule is looking for.',
  },
  noActions: {
    title: 'No Actions',
    body: 'Add an Action to define what the rule should do.',
  },
};

export const rulesRoutes = {
  new: 'new',
  edit: 'edit',
  list: 'list',
  view: 'view',
};

export const ruleTextFields = {
  id: {
    label: 'Id',
  },
  ruleName: {
    label: 'Rule Name',
  },
  source: {
    label: 'Source',
  },
  resourceTypes: {
    label: 'Resource Types',
    helperText: 'Comma separated',
  },
  definition: {
    label: 'Definition',
    helperText:
      'View our documentation to learn more about building rule definitions.',
  },
  actions: {
    label: 'Actions',
    helperText:
      'View our documentation to learn more about defining rule actions.',
  },
  operator: {
    label: 'Operator',
  },
  status: {
    label: 'Rule Status',
  },
  createdAt: {
    label: 'Created At',
  },
  updatedAt: {
    label: 'Updated At',
  },
  input: {
    label: 'Input Payload',
    helperText: 'The input/payload to test the rule against.',
  },
  output: {
    label: 'Output',
    helperText: 'The output of the rule assessment.',
  },
};

export const criterionTextFields = {
  name: {
    label: 'Name',
    helperText: 'A name for the criterion',
  },
  key: {
    label: 'Key',
    helperText: 'The key path to the value',
  },
  operator: {
    label: 'Operator',
    helperText: 'How you want to evaluate the value',
  },
  value: {
    label: 'Value',
    helperText: 'The value you want to evaluate',
  },
};

export const ruleSnackbars = {
  create: 'Successfully created rule',
  update: 'Successfully updated rule',
};

export const listRulesToolbarContent = {
  checkmark: '✓',
  filtersLabel: 'Filters:',
  defaultOptionLabel: 'All',
  sourceLabel: 'Source',
  statusLabel: 'Status',
  resourceTypesLabelDefault: 'All Types',
  resourceTypesSelected: (count: number): string => `${count} Selected`,
  clearFilters: 'Clear Filters',
};

export const ruleLocalStorageKeys = {
  rule: 'rule',
  jsonForm: 'jsonForm',
};

export const listRulesGridContent = {
  ruleName: 'Rule Name',
  source: 'Source',
  resourceTypes: 'Resource types',
  status: 'Status',
  updatedAt: 'Last Updated',
  actions: 'Actions',
};

export const actionButtonText = {
  run: 'Run',
  edit: 'Edit',
  delete: 'Delete',
};

export type Rule = {
  source: string;
  resourceTypes?: string[];
  actions: Action[];
  definition: Definition;
  status: RuleStatus;
};

export type Definition = Omit<
  GetRuleQuery['getRule']['definition'],
  '__typename'
>;

export type Action = Omit<GetRuleQuery['getRule']['actions'], '__typename'>;
