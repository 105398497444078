import { useMutation } from '@apollo/client';
import {
  HHButton,
  HHLoadingButton,
} from '@hinge-health/react-component-library';
import { Grid } from '@mui/material';
import { FormEvent, useEffect, useState } from 'react';
import { UUIDv4 } from 'uuid-v4-validator';
import { buttonLabels } from '../../../constants/hinge-connect-constants.constant';
import { Rule, ruleTextFields } from '../../constants/rules.constant';
import {
  CreateRuleAssessmentDocument as CreateRuleAssessment,
  CreateRuleAssessmentInput,
} from '../../types';
import { AceEditorComponent } from '../ace-editor-component';
import { isValidJson } from '../json-utils';

const RunRuleForm = ({
  handleClose,
  loading,
  rule,
  ruleId,
}: {
  handleClose: () => void;
  loading: boolean;
  rule: Rule;
  ruleId: string;
}): JSX.Element => {
  const initInput = '';
  const initOutput = '';
  const ruleName = rule?.definition?.name || '';

  const [input, setInput] = useState(initInput);
  const [output, setOutput] = useState(initOutput);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const randomUUID = UUIDv4.generate();

  const [createRuleAssessment, { data, error }] = useMutation(
    CreateRuleAssessment,
    {
      onCompleted: () => {
        setOutput(JSON.stringify(data, null, 2));
      },
      onError: () => {
        setOutput(JSON.stringify(error, null, 2));
      },
    },
  );

  const onSubmit = async (input: string): Promise<void> => {
    if (input !== null && input !== undefined) {
      const jsonPayload = JSON.parse(input);
      const createRuleAssessmentInput: CreateRuleAssessmentInput = {
        resource: JSON.stringify(jsonPayload),
        resourceId: randomUUID,
        resourceType: 'TEST',
        ruleIds: [ruleId],
        source: rule.source || 'TEST',
        userId: randomUUID,
      };
      await createRuleAssessment({
        variables: { createRuleAssessmentInput },
      });
    }
  };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    onSubmit(input);
  };

  useEffect(() => {
    const readyToSubmit = !!input && isValidJson(input);
    setSubmitEnabled(readyToSubmit);
  }, [input]);

  return (
    <form onSubmit={handleSubmit} data-testid="run-rule-form">
      <Grid justifyContent={'center'} spacing={2} container>
        <Grid item xs={5.5} padding={2} width={'100%'}>
          <AceEditorComponent
            label={ruleTextFields.input.label}
            value={input}
            helperText={ruleTextFields.input.helperText}
            onChange={setInput}
            dataTestId="definition-editor"
            data-testid={ruleTextFields.input.label}
            key={'input-for-rule-run-action'}
          />
        </Grid>
        <Grid
          display={'grid'}
          alignItems={'center'}
          justifyContent={'center'}
          padding={0}
          margin={0}
          item
          xs={1}
        >
          <HHLoadingButton
            hhVariant="variant-bypass"
            variant="contained"
            type="submit"
            loading={loading}
            data-testid="run-rule-button"
            disabled={!submitEnabled}
          >
            {buttonLabels.run}
          </HHLoadingButton>
        </Grid>
        <Grid item xs={5.5} width={'100%'} padding={3}>
          <AceEditorComponent
            label={ruleTextFields.output.label}
            value={output}
            helperText={ruleTextFields.output.helperText}
            onChange={setOutput}
            dataTestId="definition-editor"
            data-testid={ruleTextFields.definition.label}
            key={ruleName + '-rule-output'}
            readonly={true}
          />
        </Grid>
        <Grid item xs={10} padding={3} width={'100%'} />
        <Grid
          direction="row"
          spacing={4}
          justifyContent="right"
          marginTop="16px"
          container
          item
          xs={2}
        >
          <HHButton
            hhVariant="text"
            type="button"
            sx={{ margin: '1rem' }}
            onClick={handleClose}
          >
            {buttonLabels.cancel}
          </HHButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default RunRuleForm;
