import {
  HHBox,
  HHSnackbarAlert,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Add } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { tabRoutes } from '../../constants/hinge-connect-constants.constant';
import { useSnackbar } from '../../hooks/use-snackbar';
import { rulesRoutes } from '../constants/rules.constant';
import {
  ListRulesContextProvider,
  useListRulesContext,
} from '../contexts/list-rules.context';
import { useDeleteRule } from '../hooks/use-delete-rule';
import { ListRulesGrid } from './list-rules-grid';
import { ListRulesToolbar } from './list-rules-toolbar';

export const ListRulesViewContainer = (): JSX.Element => {
  const { listRulesData, listRulesLoading, refetchListRules, listRulesError } =
    useListRulesContext();

  const {
    deleteRule,
    data: deleteRuleData,
    loading: deleteRuleLoading,
    error: deleteRuleError,
    errorMessages: deleteRuleErrorMessages,
  } = useDeleteRule((): void => {
    // do nothing
  });

  const {
    snackbar: deleteRuleSnackbar,
    handleClose: handleDeleteRuleSnackbarClose,
  } = useSnackbar({
    data: deleteRuleData,
    error: deleteRuleError,
    errorMessages: deleteRuleErrorMessages,
    successMessage: 'Rule deleted successfully',
  });

  return (
    <>
      <HHSnackbarAlert
        message={deleteRuleSnackbar.message}
        severity={deleteRuleSnackbar.severity}
        verticalAlignment="top"
        horizontalAlignment="center"
        hhVariant="standard"
        open={deleteRuleSnackbar.open}
        onClose={handleDeleteRuleSnackbarClose}
        autoHideTime={
          deleteRuleSnackbar.severity === 'success' ? 3000 : undefined
        }
      />
      <HHTypography hhVariant="h2">Rules</HHTypography>
      <ListRulesToolbar rules={listRulesData?.listRules?.rules ?? []} />
      <HHStack direction="row" justifyContent="space-between">
        <ListRulesGrid
          listRulesData={listRulesData}
          listRulesLoading={listRulesLoading}
          refetchListRules={refetchListRules}
          deleteRule={deleteRule}
          deleteRuleLoading={deleteRuleLoading}
          listRulesError={listRulesError}
          copyRuleLoading={false}
        />
        <Fab
          color="primary"
          size="large"
          aria-label="add"
          href={`/${tabRoutes.baseRoute}/${tabRoutes.rules}/${rulesRoutes.new}`}
          sx={{
            position: 'fixed',
            bottom: 32,
            right: 32,
          }}
        >
          <Add />
        </Fab>
      </HHStack>
    </>
  );
};

export const ListRulesView = (): JSX.Element => (
  <ListRulesContextProvider>
    <HHBox paddingY="1rem">
      <ListRulesViewContainer />
    </HHBox>
  </ListRulesContextProvider>
);
