import { useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { HCModal } from '../../../components/hinge-connect-modal';
import { rulesTabContent } from '../../constants/rules.constant';
import { GetRuleDocument } from '../../types';
import RunRuleForm from './run-rule-form';

export const RunRuleModal = ({
  ruleId,
  isOpen,
  handleClose,
  runRuleLoading,
}: {
  ruleId: string;
  isOpen: boolean;
  handleClose: () => void;
  runRuleLoading: boolean;
}): JSX.Element => {
  const { data, loading } = useQuery(GetRuleDocument, {
    variables: { id: ruleId },
    skip: !isOpen,
  });

  const rule = data?.getRule;

  return (
    <HCModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={
        loading
          ? 'Loading Rule...'
          : rulesTabContent.runRule.title + ' for: ' + rule?.definition.name
      }
      component={
        loading ? (
          <LinearProgress />
        ) : (
          <RunRuleForm
            handleClose={handleClose}
            loading={runRuleLoading}
            rule={rule}
            ruleId={ruleId}
          />
        )
      }
    />
  );
};
